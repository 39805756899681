export type TSvgComponent = React.FunctionComponent<React.SVGAttributes<SVGElement>>

export type TCustomLink = {
    upperText?: string | null
    text: string
    href: string
    target?: string
    onClick?: () => Promise<void> | null | void
    truncate?: boolean
}

export enum SortOrder {
    NONE = 'NONE',
    DESC = 'DESC',
    ASC = 'ASC',
}

export enum SortFieldParts {
    PRICE = 'price',
    YEAR = 'year',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
    SOLD_AT = 'soldAt',
    VENDOR_CODE = 'vendorCode',
}

export enum SortFieldCars {
    PRICE = 'price',
    YEAR = 'year',
    CREATED_AT = 'createdAt',
    MILEAGE = 'mileage',
}

export enum SortFieldActionLogs {
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
    VENDOR_CODE = 'vendorCode',
}

export enum SortIconType {
    NUMERIC = 'NUMERIC',
    ALPHABETIC = 'ALPHABETIC',
}

export enum SortIconColor {
    INACTIVE = '#C0BFC7',
    ACTIVE = '#BA1274',
}

export type KeyboardEventLocal = {
    code: string
    preventDefault: () => void
    shiftKey: boolean
}

export type RangeInput = {
    field?: string
    from?: number
    to?: number
}

export type ApiOption = {
    id: number
    title: string
    count?: number
    bodyTypeTitle?: string
    vinCode?: string
    description?: string
    markTitle?: string
    modelTitle?: string
    year?: number
    customTitle?: string
    typeId?: number
    partTitle?: string
    modificationTitle?: string
}

export type CatalogMark = {
    code: string
    id: string
    name: string
}

export type CatalogModel = {
    name: string
    markId: string
    id: string
}

export type CatalogModification = {
    id: string
    name: string
    markId: string
    modelId: string
    bodyTypeId: string | number
    bodyTypeTitle?: string
    yearFrom: number
    yearTo: number
}

export type CatalogPartGroup = {
    id: number
    title: string
}

export type CatalogPartType = {
    id: string | number
    title: string
    avito_type_id: string | number
    avito_type_title: string
    synonyms?: string
    forAllBodyType?: boolean
}

export type SelectOptionLocal = {
    value: string | number
    name: string
    subName?: string
    subValue?: string | number
    subText?: string
    badgeNames?: string[]
    description?: string
    customTitle?: string
    partsId?: string[]
    partData?: {
        typeId?: number
        title?: string
        customTitle?: string
        description?: string
    }
}

export enum fieldTypes {
    void = 'void',
    input = 'input',
    switch = 'switch',
    select = 'select',
    selectSearch = 'selectSearch',
    readOnly = 'readOnly',
    url = 'url',
    checkboxGroup = 'checkboxGroup',
    checkbox = 'checkbox',
    textarea = 'textarea',
    files = 'files',
    starRating = 'starRating',
    checkboxGroupColumn = 'checkboxGroupColumn',
    partNumber = 'partNumber',
    applicability = 'applicability',
    applicabilityButtons = 'applicabilityButtons',
    applicabilitySelected = 'applicabilitySelected',
    applicabilitySuggestion = 'applicabilitySuggestion',
}

export enum valueTypes {
    string = 'string',
    number = 'number',
    boolean = 'boolean',
    price = 'price',
    files = 'files',
    array = 'array',
}

export type ApiImageUpload = {
    id: string
    userId: string
    index: number
    entityId: string
    entityType: 'car' | 'part'
    localPath?: string
    cloudPath?: string
    createdAt?: string
    updatedAt?: string
}

export enum ECurrency {
    USD = 'USD',
    EUR = 'EUR',
    RUR = 'РУБ',
    AED = 'AED',
    KZT = 'KZT',
    BYN = 'BYN',
}

export type TCurrencyKeys = keyof typeof ECurrency
