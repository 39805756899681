import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { apiDocument } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'

const GetPartsIdByDocumentId =
    (id: string) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<string[]> => {
        const { userData } = getState()
        try {
            const { data } = await apiDocument.post(
                '/getPartsIdByDocumentId',
                {
                    userId: userData.smsAuth.userId,
                    id,
                },
                {
                    headers: {
                        Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
                    },
                },
            )
            return data.data
        } catch (e) {
            console.error('load parts by documentId error', e)
            return []
        }
    }

export default GetPartsIdByDocumentId
