import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { EDocumentType } from '../../../types/document/documentTypes'
import { apiDocument } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getDocumentsSelectSearch =
    (type: EDocumentType) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { userData } = getState()

        dispatch({ type: storeDispatchTypes.setDocumentsLoader, value: { field: 'documents', value: true } })
        try {
            const params = {
                type,
            }
            const {
                data: { data },
            } = await apiDocument.post(
                '/getDocumentList/getDocumentsSelectSearch',
                {
                    userId: userData.smsAuth.userId,
                    params: params,
                },
                {
                    headers: {
                        Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
                    },
                },
            )
            dispatch({
                type: storeDispatchTypes.setAllDocuments,
                value: {
                    documents: data,
                },
            })
        } catch (e) {
            console.error('load visible documents error', e)
        } finally {
            dispatch({ type: storeDispatchTypes.setDocumentsLoader, value: { field: 'documents', value: false } })
        }
    }

export default getDocumentsSelectSearch
