import React, { useRef } from 'react'
import { useLocation } from 'react-router'
import { Outlet } from 'react-router-dom'

import classNames from 'classnames'

import Footer from '../components/_atoms/Footer/Footer'
import SubscriptionExpiryBanner from '../components/_atoms/SubscriptionExpiryBanner/SubscriptionExpiryBanner'
import Sidebar from '../components/_molecules/Sidebar/Sidebar'
import Header from '../components/_orgamisms/Header/Header'
import { useAuthGuard } from '../hooks/user/useAuthGuard'
import { useCheckPerms } from '../hooks/user/useCheckPerms'
import { useGetAccountCashierInfo } from '../hooks/user/useGetAccountCashierInfo'
import { useGetUserActiveCurrency } from '../hooks/user/useGetAccountCurrency'
import { useOnboarding } from '../hooks/user/useOnboarding'
import { usePayWall } from '../hooks/user/usePayWall'
import { adminRouteAlias } from '../router/adminRouteAlias'

import styles from './styles.module.scss'

function Admin() {
    useAuthGuard()
    usePayWall()
    useGetAccountCashierInfo()
    useOnboarding()
    useGetUserActiveCurrency()
    useCheckPerms()
    const mainPanel = useRef(null)
    const location = useLocation()

    const isOnboardingPage = location.pathname.includes(adminRouteAlias.onboarding.location)

    return (
        <>
            <div className={styles.adminWrap}>
                <Header />
                <div
                    className={classNames(styles.adminWrap__sideBarContent, {
                        [styles.adminWrap__linearBackground]: !isOnboardingPage,
                    })}
                >
                    <Sidebar />
                    <div className={styles.adminWrap__content} ref={mainPanel}>
                        <SubscriptionExpiryBanner />
                        <Outlet />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Admin
