import React, { useEffect, useRef, useState } from 'react'

import { colors, fontSizes } from '../../../constants/salesStyles'
import { KeyboardEventLocal, SelectOptionLocal } from '../../../types/common/commonTypes'
import HoverOverlay from '../../_atoms/HoverOverlay/HoverOverlay'
import SalesText from '../../_atoms/SalesText/SalesText'
import SalesSelectOptions from '../SalesSelectOptions/SalesSelectOptions'
import SalesSelectSearchSuffix from '../SalesSelectSearchSuffix/SalesSelectSearchSuffix'

import styles from './styles.module.scss'

interface IProps {
    text: string
    options: SelectOptionLocal[]
    maxOptions: number
    placeholder: string
    onChangeValue: (value: string | number, name: string) => void
    errorMessage?: string
    widthInPixelsOptions?: number
}

const SalesSelect = ({
    text,
    options,
    maxOptions,
    placeholder,
    onChangeValue,
    errorMessage,
    widthInPixelsOptions,
}: IProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [currentOption, setCurrentOption] = useState(-1)
    const divRef = useRef<HTMLDivElement | null>(null)
    const findCurrentOption = () => options.findIndex(option => option.name === text)

    const updateField = (option: SelectOptionLocal) => {
        onChangeValue(option.value, option.name)
    }

    const nextOption = () => {
        if (currentOption == maxOptions - 1) {
            return
        }
        const nextOptionIndex = currentOption + 1
        updateField(options[nextOptionIndex])
        setCurrentOption(nextOptionIndex)
    }

    const prevOption = () => {
        if (currentOption - 1 < 0) {
            return
        }

        const prevOption = currentOption - 1
        updateField(options[prevOption])
        setCurrentOption(prevOption)
    }

    const keyHandler = (event: KeyboardEventLocal) => {
        switch (true) {
            case event.code === 'ArrowDown' || event.code === 'ArrowRight':
                event.preventDefault()
                nextOption()
                break
            case event.code === 'ArrowUp' || event.code === 'ArrowLeft':
                event.preventDefault()
                prevOption()
                break
            case event.code === 'Space' || event.code === 'Enter' || event.code === 'NumpadEnter':
                setIsOpen(!isOpen)
                break
            default:
                return
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (divRef.current && !divRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [divRef])

    useEffect(() => {
        if (isOpen) {
            setCurrentOption(findCurrentOption())
        }
    }, [isOpen])

    return (
        <div
            className={`${styles.wrap} ${errorMessage ? styles.error : ''}`}
            tabIndex={0}
            onKeyDown={keyHandler}
            ref={divRef}
            onClick={() => {
                setIsOpen(!isOpen)
            }}
        >
            <HoverOverlay tooltipText={errorMessage} maxWidthInPixels={300}>
                <>
                    <div className={styles.select}>
                        <SalesText text={text || placeholder} color={colors.grayDisabled} />
                        <div className={styles.suffixElement}>
                            <SalesSelectSearchSuffix
                                text={text}
                                isLoading={false}
                                clearText={() => updateField({ value: '', name: '' })}
                            />
                        </div>
                    </div>
                    <>
                        {!isOpen ? null : (
                            <SalesSelectOptions
                                options={options}
                                maxOptions={maxOptions}
                                currentOption={currentOption}
                                updateField={updateField}
                                hideOptions={() => setIsOpen(false)}
                                noOptionsText={''}
                                fontSize={fontSizes.s}
                                widthInPixels={widthInPixelsOptions}
                            />
                        )}
                    </>
                </>
            </HoverOverlay>
        </div>
    )
}

export default SalesSelect
