import React from 'react'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

import { colors, fontSizes, fontWeights } from '../../../constants/salesStyles'

import styles from './styles.module.scss'

interface IProps {
    fontSize?: fontSizes
    fontWeight?: fontWeights
    color?: colors
    text: string
    marginRightPixels?: number
    marginLeftPixels?: number
    marginTopPixels?: number
    marginBottomPixels?: number
    backgroundColor?: colors
    paddingRightPixels?: number
    paddingLeftPixels?: number
    paddingTopPixels?: number
    paddingBottomPixels?: number
    shrinkToFit?: boolean
    onClick?: () => void
    subText?: string
    href?: string
    subHref?: string
    letterSpacing?: number
    className?: string
    maxLines?: number
    beforeText?: JSX.Element
}

const SalesText = ({
    fontSize,
    fontWeight,
    color,
    text,
    marginRightPixels,
    marginLeftPixels,
    marginTopPixels,
    marginBottomPixels,
    backgroundColor,
    paddingRightPixels,
    paddingLeftPixels,
    paddingTopPixels,
    paddingBottomPixels,
    shrinkToFit,
    onClick,
    subText,
    href,
    subHref,
    letterSpacing,
    className,
    maxLines,
    beforeText,
}: IProps) => (
    <div
        className={classNames(styles.wrap, { [styles.shrinkwrap]: shrinkToFit }, className)}
        style={{
            fontSize: fontSize ? fontSize : fontSizes.m,
            fontWeight: fontWeight ? fontWeight : fontWeights.normal,
            color: color ? color : colors.black,
            marginRight: marginRightPixels ? marginRightPixels + 'px' : 0,
            marginLeft: marginLeftPixels ? marginLeftPixels + 'px' : 0,
            marginTop: marginTopPixels ? marginTopPixels + 'px' : 0,
            marginBottom: marginBottomPixels ? marginBottomPixels + 'px' : 0,
            backgroundColor: backgroundColor ? backgroundColor : 'transparent',
            paddingRight: paddingRightPixels ? paddingRightPixels + 'px' : 0,
            paddingLeft: paddingLeftPixels ? paddingLeftPixels + 'px' : 0,
            paddingTop: paddingTopPixels ? paddingTopPixels + 'px' : 0,
            paddingBottom: paddingBottomPixels ? paddingBottomPixels + 'px' : 0,
            letterSpacing: letterSpacing ? letterSpacing + 'em' : 0,
        }}
        onClick={onClick}
    >
        {beforeText && <span>{beforeText}</span>}
        <div className={classNames({ [styles.clamped]: maxLines })} style={{ WebkitLineClamp: maxLines }}>
            {subHref ? (
                <span>
                    {text + ' '}
                    <Link to={subHref} style={{ color: colors.black }}>
                        {subText}
                    </Link>
                </span>
            ) : href ? (
                <Link to={href}>{subText ? `${text} ${subText}` : text}</Link>
            ) : (
                <span>{subText ? `${text} ${subText}` : text}</span>
            )}
        </div>
    </div>
)

export default SalesText
