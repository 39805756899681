import React, { useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AnyAction } from 'redux'

import { colors, fontSizes } from '../../../constants/salesStyles'
import getMarketplacesContact from '../../../modules/redux/sales/getMarketplacesContact'
import getShopInfo from '../../../modules/redux/sales/getShopInfo'
import updateMarketplacesContact from '../../../modules/redux/sales/updateMarketplacesContact'
import updateShopInfo from '../../../modules/redux/sales/updateShopInfo'
import {
    apiMarketplacesContact,
    apiShopInfo,
    contactFields,
    contactInput,
    contactState,
    salesContactActionTypes,
} from '../../../types/sales/salesTypes'
import Loader from '../../_atoms/Loader/Loader'
import SalesText from '../../_atoms/SalesText/SalesText'
import SalesButtonStandard from '../../_molecules/SalesButtonStandard/SalesButtonStandard'
import SalesCard from '../../_molecules/SalesCard/SalesCard'
import SalesTableMarketplacesContact from '../../_molecules/SalesTableMarketplacesContact/SalesTableMarketplacesContact'

const contactReducer = (state: contactState, action: AnyAction) => {
    const getFieldIndex = (fieldToSearch: contactFields) =>
        state.fields.findIndex((field: contactInput) => field.field === fieldToSearch)

    const getUpdatedState = () => {
        const stateFields = [...state.fields]

        const fieldToUpdateIndex = getFieldIndex(action.value.field)
        stateFields[fieldToUpdateIndex] = { ...stateFields[fieldToUpdateIndex], value: action.value.value }

        const specialPropsToUpdate = action.value.specialProps
        if (specialPropsToUpdate) {
            stateFields[fieldToUpdateIndex] = {
                ...stateFields[fieldToUpdateIndex],
                specialProps: { ...specialPropsToUpdate },
            }
        }
        return { ...state, fields: stateFields }
    }
    const getUpdatedStateSpecialProps = () => {
        const updatedFields = state.fields.map((item: contactInput) => {
            if (action.type === salesContactActionTypes.updateSpecialProps) {
                if (item.field === action.value.field) {
                    return {
                        ...item,
                        specialProps: {
                            ...item.specialProps,
                            [action.value.specialPropsField]: action.value.specialPropsValue,
                        },
                    }
                } else {
                    return item
                }
            }
        })
        return { ...state, fields: updatedFields }
    }
    switch (action.type) {
        case salesContactActionTypes.updateField:
            return getUpdatedState()
        case salesContactActionTypes.initializeState:
            return action.value.state
        case salesContactActionTypes.updateSpecialProps:
            return getUpdatedStateSpecialProps()
        default:
            return state
    }
}

interface IProps {
    initialState: contactState
    apiObject: apiMarketplacesContact | apiShopInfo
    contactsType: 'marketplacesContact' | 'shopInfo'
    footerText?: string
    isLoading: boolean
    checkboxIndex?: number
    outerTitle?: string
}

const SalesCardContact = ({
    initialState,
    apiObject,
    contactsType,
    footerText,
    isLoading,
    checkboxIndex,
    outerTitle,
}: IProps) => {
    const [isEdited, setIsEdited] = useState(false)
    const dispatchRedux = useDispatch()

    const [state, dispatch] = useReducer(contactReducer, initialState)
    const getApiContactObjectFromState = () => {
        let apiObjectCopy = { ...apiObject }

        state.fields.map((item: contactInput) => {
            apiObjectCopy = { ...apiObjectCopy, [item.field]: item.value }
        })
        return apiObjectCopy
    }

    const onChangeCheckboxValue = (newValue: boolean) => {
        if (checkboxIndex || checkboxIndex === 0) {
            dispatch({
                type: salesContactActionTypes.updateField,
                value: { field: state.fields[checkboxIndex].field, value: newValue },
            })
        }
    }

    const getVisibleFields = () => state.fields.filter((field: contactInput) => !field.specialProps?.isHidden)

    useEffect(() => {
        switch (contactsType) {
            case 'marketplacesContact':
                dispatchRedux(getMarketplacesContact())
                break
            case 'shopInfo':
                dispatchRedux(getShopInfo())
                break
        }
    }, [])

    useEffect(() => {
        if (!isLoading) {
            dispatch({ type: salesContactActionTypes.initializeState, value: { state: initialState } })
        }
    }, [isLoading])

    return (
        <SalesCard
            widthInPixels={520}
            heightInPixels={isLoading ? 397 : undefined}
            outerTitle={outerTitle || 'Контакты'}
            footer={isEdited}
            preserveMarginTop
            overflowY={'visible'}
            footerContent={
                <SalesButtonStandard
                    text={'Сохранить'}
                    backgroundColor={colors.white}
                    marginRightPixels={0}
                    onClick={() => {
                        switch (contactsType) {
                            case 'marketplacesContact':
                                dispatchRedux(
                                    updateMarketplacesContact(getApiContactObjectFromState() as apiMarketplacesContact),
                                )
                                break
                            case 'shopInfo':
                                dispatchRedux(updateShopInfo(getApiContactObjectFromState() as apiShopInfo))
                                break
                        }
                        setIsEdited(false)
                    }}
                />
            }
        >
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <SalesTableMarketplacesContact
                        isEdited={isEdited}
                        setIsEdited={setIsEdited}
                        contacts={getVisibleFields()}
                        dispatch={dispatch}
                    />
                    {/* {checkboxIndex || checkboxIndex === 0
                        ? <SalesCheckboxSecondNumber
                            setIsEdited={setIsEdited}
                            isChecked={Boolean(state.fields[checkboxIndex].value)}
                            price={state.fields[checkboxIndex].specialProps?.checkboxPrice || 0}
                            onChangeValue={onChangeCheckboxValue}
                        />
                        : null
                    } */}

                    {footerText ? (
                        <SalesText
                            text={footerText}
                            color={colors.grayLight}
                            fontSize={fontSizes.xs}
                            marginTopPixels={checkboxIndex ? 0 : 30}
                        />
                    ) : null}
                </>
            )}
        </SalesCard>
    )
}

export default SalesCardContact
