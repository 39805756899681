import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import setPartSaleInfo from '../../../modules/redux/partList/setPartSaleInfo'
import { useSelector } from '../../../modules/store/customSelector'
import { EDocumentType } from '../../../types/document/documentTypes'
import { Part } from '../../../types/part/partTypes'
import SelectableMartketplaceList from '../../_atoms/SelectableMarketplaceList/SelectableMartketplaceList'

import styles from './styles.module.scss'

interface IProps {
    parts: Part[]
    isOpen: boolean
    closeModal: () => void
    count: number
    openDataDocumentModal: () => void
    setChecksFromModalSellParts: ({
        documentType,
        marketplace,
    }: {
        documentType: EDocumentType[]
        marketplace?: string
    }) => void
    setCheckedParts: () => void
}

const ModalSellParts = ({
    parts,
    isOpen,
    closeModal,
    count,
    openDataDocumentModal,
    setChecksFromModalSellParts,
    setCheckedParts,
}: IProps) => {
    const dispatch = useDispatch()

    const [selectedChecks, setSelectedChecks] = useState<EDocumentType[]>([])
    const [selectedMarketplace, setSelectedMarketplace] = useState<string | null>(null)

    const marketPlacesInfo = useSelector(state => state.sales.marketplaces.info)

    const onCloseModal = () => {
        setCheckedParts()
        closeModal()
    }

    const handleComponentClick = (index: string) => {
        setSelectedMarketplace(index)
    }

    const confirmSoldPart = () => {
        if (selectedChecks.length) {
            openDataDocumentModal()
            setChecksFromModalSellParts({
                documentType: selectedChecks,
                marketplace: selectedMarketplace != null ? selectedMarketplace : undefined,
            })
        } else {
            setCheckedParts()
            parts.forEach(part => {
                dispatch(
                    setPartSaleInfo(part, part.price, selectedMarketplace != null ? selectedMarketplace : undefined),
                )
            })
        }
        closeModal()
    }

    const handleCheckboxChange = (check: EDocumentType) => {
        const newSelectedChecks = [...selectedChecks]
        const idExists = selectedChecks.includes(check)

        if (idExists) {
            newSelectedChecks.splice(newSelectedChecks.indexOf(check), 1)
        } else {
            newSelectedChecks.push(check)
        }

        setSelectedChecks(newSelectedChecks)
    }

    return (
        <>
            <Modal className={styles.modalSellPart} show={isOpen} onHide={onCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Продажа</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Выбрано товаров: {count}</h4>
                    <Form>
                        <div className={styles.row}>
                            <Form.Check
                                label={'Расходная накладная'}
                                onChange={() => handleCheckboxChange(EDocumentType.Invoice)}
                            />
                            <Form.Check
                                label={'Товарный чек'}
                                onChange={() => handleCheckboxChange(EDocumentType.Receipt)}
                            />
                        </div>
                    </Form>
                    <h4 className={styles.title}>Площадка продажи</h4>
                    <SelectableMartketplaceList
                        componentData={marketPlacesInfo}
                        selectedComponent={selectedMarketplace}
                        onComponentClick={handleComponentClick}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.buttonGroup}>
                        <Button
                            size="lg"
                            variant="primary"
                            onClick={confirmSoldPart}
                            disabled={!selectedChecks.length && !selectedMarketplace}
                        >
                            Продано
                        </Button>
                        <Button size="lg" variant="secondary" onClick={onCloseModal}>
                            Отменить
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalSellParts
