import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Dropdown, Form, Table } from 'react-bootstrap'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'
import { browserName } from 'react-device-detect'
import { useNavigate, useSearchParams, Link, useParams } from 'react-router-dom'

import moment from 'moment'

import 'moment/locale/ru'
import Badge from '../../components/_atoms/Badge/Badge'
import CategoryIcon from '../../components/_atoms/CategogyIcon/CategoryIcon'
import CopyButton from '../../components/_atoms/CopyButton/CopyButton'
import ListNoItems from '../../components/_atoms/ListNoItems/ListNoItems'
import ListPagination from '../../components/_atoms/ListPagination/ListPagination'
import ListSkeleton from '../../components/_atoms/ListSkeleton/ListSkeleton'
import SalesButton from '../../components/_atoms/SalesButton/SalesButton'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import TableHeadPartSearch from '../../components/_atoms/TableHeadPartSearch/TableHeadPartSearch'
import TableHeadRangeFilter from '../../components/_atoms/TableHeadRangeFilter/TableHeadRangeFilter'
import ColumnSort from '../../components/_molecules/ColumnSort/ColumnSort'
import ModalViewPart from '../../components/_molecules/ModalViewPart/ModalViewPart'
import NavigationButtons from '../../components/_molecules/NavigationButtons/NavigationButtons'
import ResetFilter from '../../components/_molecules/ResetFilter/ResetFilter'
import SearchComponent from '../../components/_molecules/SearchComponent/SearchComponent'
import TableHeadCategorySearchParts from '../../components/_molecules/TableHeadCategorySearchParts/TableHeadCategorySearchParts'
import TableHeadMarkSearchParts from '../../components/_molecules/TableHeadMarkSearchParts/TableHeadMarkSearchParts'
import TableHeadModelSearchParts from '../../components/_molecules/TableHeadModelSearchParts/TableHeadModelSearchParts'
import TableHeadPartNumberSearchParts from '../../components/_molecules/TableHeadPartNumberSearchParts/TableHeadPartNumberSearchParts'
import CellActionsParts from '../../components/_orgamisms/CellActionsParts/CellActionsParts'
import CellThumbCarousel from '../../components/_orgamisms/CellThumbCarousel/CellThumbCarousel'
import { FilterSelect } from '../../constants/filterSelect'
import { pages } from '../../constants/parts/navigationButtonsPages'
import { colors, fontSizes, fontWeights } from '../../constants/salesStyles'
import useResetFiltersOnUnmount from '../../hooks/partList/useResetFiltersOnUnmount'
import DatahubApiService from '../../modules/api/DatahubApiService'
import getCarById from '../../modules/redux/createUpdateForm/getCarById'
import getDocumentsSelectSearch from '../../modules/redux/documentList/GetDocumentsSelectSearch'
import loadPartList from '../../modules/redux/partList/LoadPartList'
import getSummaryByUserId from '../../modules/redux/sales/getSummaryByUserId'
import { useDispatch } from '../../modules/store/customDispatch'
import { useSelector } from '../../modules/store/customSelector'
import { storeDispatchTypes } from '../../modules/store/storeDispatchTypes'
import { adminRouteAlias, getAdminNavigationPath } from '../../router/adminRouteAlias'
import { SortFieldParts, SortIconType } from '../../types/common/commonTypes'
import { EDocumentType } from '../../types/document/documentTypes'
import {
    Marketplaces,
    MarketplacesKeys,
    Part,
    PartListPageParams,
    PartListSearchParams,
} from '../../types/part/partTypes'
import { countDaysInStock, formatDate, num_word } from '../../utils/converters'
import { getCurrencySymbol } from '../../utils/getCurrencySymbol'
import { colWidth } from '../../utils/stylePatches'

import styles from './styles.module.scss'

const SoldPartList = () => {
    const params = useParams<PartListPageParams>()
    const dispatch = useDispatch()
    const [period, setPeriod] = useState<FilterSelect>(FilterSelect.ALL)
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [counts, setCounts] = useState<Record<string, number>>()
    const [isPartModalOpen, setIsPartModalOpen] = useState(false)
    const navigate = useNavigate()
    const [pagesParts, setPagesParts] = useState(pages)

    const {
        currentPart,
        isLoading: { partList: isLoading },
        partList: { data: parts, dataCount: itemsCount, total: itemsTotal, draftCount, archivedCount, soldCount },
        filters: {
            car: carFilter,
            modelId,
            markId,
            categoryId,
            partId,
            partNumber,
            partName,
            priceRange,
            dateRange,
            page,
            perPage,
            filterVendorCode,
            marketplace,
            document,
        },
        sortOrder,
        currentCurrency,
    } = useSelector(state => state.partList)
    const canShowFinanceInfo = useSelector(state => state.userData.perms?.canShowFinanceInfo)

    const { from: priceFrom, to: priceTo } = priceRange || {}
    const { from: dateFrom, to: dateTo } = dateRange || {}
    const { documentId } = document || {}

    const userId = useSelector(state => state.userData.smsAuth.userId)
    const {
        documents,
        loaders: { documents: isLoadingDocuments },
    } = useSelector(state => state.documentList)

    // Фильтрация по параметрам
    const filterParts = useCallback(
        (type: storeDispatchTypes, value: any) =>
            dispatch({
                type,
                value,
            }),
        [dispatch],
    )

    const updateCounts = useCallback(() => {
        if (!isLoading) {
            setCounts({
                itemsTotal,
                draftCount,
                archivedCount,
                soldCount,
            })
        }
    }, [archivedCount, draftCount, isLoading, itemsTotal, soldCount])

    useEffect(() => {
        updateCounts()
    }, [updateCounts])

    const applyFilters = useMemo(
        () => ({
            partName: (value: string | null) =>
                filterParts(storeDispatchTypes.setPartNameTextFilterParts, { partName: value }),
            categoryId: (value: string | null) =>
                filterParts(storeDispatchTypes.setCategoryFilterParts, { filterId: value }),
            markId: (value: string | null) => filterParts(storeDispatchTypes.setMarkFilterParts, { filterId: value }),
            modelId: (value: string | null) => filterParts(storeDispatchTypes.setModelFilterParts, { filterId: value }),
            priceRange: (from: string | null, to: string | null) =>
                filterParts(storeDispatchTypes.setPriceRangeParts, { from, to }),
            partNumber: (value: string | null) =>
                filterParts(storeDispatchTypes.setPartNumberFilterParts, { filterId: value }),
            sortOrder: (field: string, order: string) =>
                filterParts(storeDispatchTypes.setPartListSort, { sortField: field, sortOrder: order }),
            marketplace: (value: string | null) =>
                filterParts(storeDispatchTypes.setPartListParamsMarketplace, { marketplace: value }),
            documentId: (value: string | null) =>
                filterParts(storeDispatchTypes.setDocumentFilterParts, { document: { documentId: value } }),
            dateRange: (from: string | Date | null, to: string | Date | null) =>
                filterParts(storeDispatchTypes.setDateRangeParts, { from, to }),
        }),
        [filterParts],
    )

    const filterPartsByMarketplace = useCallback(
        (marketplace: MarketplacesKeys | null) => {
            applyFilters.marketplace(marketplace)
        },
        [applyFilters],
    )

    const handleSelectChangeDocument = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            applyFilters.documentId(e.target.value)
        },
        [applyFilters],
    )

    // Параметры поиска
    const [searchParams, setSearchParams] = useSearchParams()
    const [isSearchParamsLoaded, setIsSearchParamsLoaded] = useState(false)

    useEffect(() => {
        dispatch(getDocumentsSelectSearch(EDocumentType.IncomingInvoice))
    }, [dispatch])

    useEffect(() => {
        setPagesParts(prevPages =>
            prevPages.map(page => ({
                ...page,
                count: counts && counts[page.countKey],
            })),
        )
    }, [counts])

    const loadParts = useCallback(async () => {
        const { partsId } = documents.find(document => document.value === documentId) || {}

        const params: PartListSearchParams = {
            pagination: {
                page,
                perPage,
            },
            order: sortOrder,
            carId: carFilter?.carId,
            modelId,
            markId,
            groupId: categoryId,
            search: partName,
            priceRange,
            partId: partId || partsId,
            partNumber,
            isSold: true,
            dateRange,
            marketplace,
        }

        dispatch(loadPartList(params))
    }, [
        carFilter?.carId,
        categoryId,
        dateRange,
        dispatch,
        documentId,
        documents,
        markId,
        marketplace,
        modelId,
        page,
        partId,
        partName,
        partNumber,
        perPage,
        priceRange,
        sortOrder,
    ])

    const loadSearchParams = useCallback(async () => {
        const searchParamsObj = Object.fromEntries(searchParams.entries())

        const searchParamCarId = searchParamsObj.carId
        const searchParamPage = searchParamsObj.p
        const searchParamPartName = searchParamsObj.pn
        const searchParamCategoryId = searchParamsObj.g
        const searchParamMarkId = searchParamsObj.markId
        const searchParamModelId = searchParamsObj.modelId
        const searchParamPriceFrom = searchParamsObj.priceFrom
        const searchParamPriceTo = searchParamsObj.priceTo
        const searchParamPartNumber = searchParamsObj.partNumber
        const searchParamSortOrder = searchParamsObj.sortOrder
        const searchParamMarketplace = searchParamsObj.marketplace
        const searchParamDateFrom = searchParamsObj.dateFrom
        const searchParamDateTo = searchParamsObj.dateTo
        const searchParamDocumentId = searchParamsObj.documentId

        if (searchParamPage !== `${page}` && (searchParamPage !== null || page !== 1)) {
            dispatch({
                type: storeDispatchTypes.setPageFilterParts,
                value: { page: Number(searchParamPage) > 1 ? Number(searchParamPage) : 1 },
            })
        }

        if (searchParamPartName !== partName) {
            applyFilters.partName(searchParamPartName)
        }

        if (searchParamCategoryId !== `${categoryId}`) {
            applyFilters.categoryId(searchParamCategoryId)
        }

        if (searchParamMarkId !== `${markId}`) {
            applyFilters.markId(searchParamMarkId)
        }

        if (searchParamModelId !== `${modelId}`) {
            applyFilters.modelId(searchParamModelId)
        }

        if (
            searchParamPriceFrom !== `${priceFrom}` ||
            searchParamPriceFrom === null ||
            searchParamPriceTo !== `${priceTo}` ||
            searchParamPriceTo === null
        ) {
            if (searchParamPriceFrom || priceFrom !== undefined || searchParamPriceTo || priceTo !== undefined) {
                applyFilters.priceRange(searchParamPriceFrom, searchParamPriceTo)
            }
        }

        if (searchParamPartNumber !== `${partNumber}`) {
            applyFilters.partNumber(searchParamPartNumber)
        }

        if (searchParamSortOrder && searchParamSortOrder !== sortOrder.map(pair => pair.join(',')).join(',')) {
            const searchParamsSet = new Set(searchParamSortOrder.split(';'))
            const sortOrderSet = new Set(sortOrder.map(pair => pair.join(',')))

            // Для каждой пары из sortOrder, которая не содержится в searchParamSortOrder
            sortOrder.forEach(pair => {
                const pairString = pair.join(',')
                if (!searchParamsSet.has(pairString)) {
                    applyFilters.sortOrder(pair[0], 'NONE')
                }
            })

            // Для каждой пары из searchParamSortOrder, которая не содержится в sortOrder
            searchParamsSet.forEach(param => {
                if (!sortOrderSet.has(param)) {
                    const [field, order] = param.split(',')
                    applyFilters.sortOrder(field, order)
                }
            })
        } else if (!searchParamSortOrder) {
            sortOrder.forEach(field => applyFilters.sortOrder(field[0], 'NONE'))
        }

        if (searchParamCarId !== `${carFilter?.carId[0]}`) {
            if (!searchParamCarId) {
                dispatch({ type: storeDispatchTypes.resetCarFilterParts })
            } else if (userId) {
                const car = await getCarById(searchParamCarId, userId)
                if (car) {
                    dispatch({
                        type: storeDispatchTypes.setCarFilterParts,
                        value: {
                            car: {
                                carId: [car.id],
                                markTitle: car.markTitle,
                                modelTitle: car.modelTitle,
                                year: car.year,
                            },
                        },
                    })
                }
            }
        } else if (!carFilter?.carId[0]) {
            dispatch({ type: storeDispatchTypes.resetCarFilterParts })
        }

        if (searchParamMarketplace !== String(marketplace)) {
            applyFilters.marketplace(searchParamMarketplace)
        }

        if (
            searchParamDateFrom !== `${dateFrom}` ||
            searchParamDateFrom === null ||
            searchParamDateTo !== `${dateTo}` ||
            searchParamDateTo === null
        ) {
            if (searchParamDateFrom || dateFrom !== undefined || searchParamDateTo || dateTo !== undefined) {
                const fromDate = new Date(Number(searchParamDateFrom))
                const toDate = new Date(Number(searchParamDateTo))

                applyFilters.dateRange(searchParamDateFrom, searchParamDateTo)
                setStartDate(fromDate)
                setEndDate(toDate)
                setPeriod(determinePeriod(fromDate, toDate))
            }
        }

        if (searchParamDocumentId !== documentId) {
            applyFilters.documentId(searchParamDocumentId)
        }

        setIsSearchParamsLoaded(true)
    }, [
        applyFilters,
        carFilter?.carId,
        categoryId,
        dateFrom,
        dateTo,
        dispatch,
        documentId,
        markId,
        marketplace,
        modelId,
        page,
        partName,
        partNumber,
        priceFrom,
        priceTo,
        searchParams,
        sortOrder,
        userId,
    ])

    // Сбрасываем флаг, если параметры изменились
    useEffect(() => {
        setIsSearchParamsLoaded(false)
    }, [searchParams])

    // Загрузка параметров из строки поиска
    useEffect(() => {
        if (!isSearchParamsLoaded) {
            loadSearchParams()
        }
    }, [loadSearchParams, isSearchParamsLoaded])

    // Загрузка параметров в строку поиска
    useEffect(() => {
        if (!isSearchParamsLoaded || isLoadingDocuments) return

        const updatedParams = new URLSearchParams(searchParams)

        const paramMap: Record<string, any> = {
            carId: carFilter?.carId?.[0],
            p: page > 1 ? String(page) : undefined,
            pn: partName,
            g: categoryId,
            markId,
            modelId,
            priceFrom,
            priceTo,
            partNumber,
            sortOrder: sortOrder.length ? sortOrder.map(pair => pair.join(',')).join(';') : undefined,
            marketplace,
            documentId,
            dateFrom,
            dateTo,
        }

        for (const [key, value] of Object.entries(paramMap)) {
            if (value === undefined || value === '' || value === null) {
                updatedParams.delete(key)
            } else {
                updatedParams.set(key, String(value))
            }
        }

        if (updatedParams.toString() !== searchParams.toString()) {
            setSearchParams(updatedParams)
        } else {
            loadParts()
        }
    }, [
        carFilter?.carId,
        categoryId,
        dateFrom,
        dateTo,
        documentId,
        loadParts,
        markId,
        marketplace,
        modelId,
        page,
        partName,
        partNumber,
        priceFrom,
        priceTo,
        isSearchParamsLoaded,
        isLoadingDocuments,
        sortOrder,
    ])

    const handlePeriodChange = useCallback((selectedPeriod: FilterSelect) => {
        setPeriod(selectedPeriod)
        setStartDate(null)
        setEndDate(null)

        const currentDate = new Date()
        let periodStartDate: Date | null = null
        let periodEndDate: Date | null = null

        switch (selectedPeriod) {
            case FilterSelect.YEAR:
                periodStartDate = moment(currentDate).startOf('year').toDate()
                periodEndDate = moment(currentDate).endOf('year').toDate()
                break
            case FilterSelect.QUARTER:
                periodStartDate = moment(currentDate).startOf('quarter').toDate()
                periodEndDate = moment(currentDate).endOf('quarter').toDate()
                break
            case FilterSelect.MONTH:
                periodStartDate = moment(currentDate).startOf('month').toDate()
                periodEndDate = moment(currentDate).endOf('month').toDate()
                break
            case FilterSelect.WEEK:
                periodStartDate = moment(currentDate).startOf('isoWeek').toDate()
                periodEndDate = moment(currentDate).endOf('isoWeek').toDate()
                break
            case FilterSelect.DAY:
                periodStartDate = moment(currentDate).startOf('day').toDate()
                periodEndDate = moment(currentDate).endOf('day').toDate()
                break
        }

        setStartDate(periodStartDate)
        setEndDate(periodEndDate)
    }, [])

    const getPeriodDuration = useCallback((period: FilterSelect): moment.unitOfTime.DurationConstructor => {
        switch (period) {
            case FilterSelect.MONTH:
                return 'month'
            case FilterSelect.QUARTER:
                return 'quarter'
            case FilterSelect.WEEK:
                return 'week'
            case FilterSelect.YEAR:
                return 'year'
            case FilterSelect.DAY:
                return 'day'
            default:
                throw new Error(`Invalid period: ${period}`)
        }
    }, [])

    const handlePreviousPeriod = useCallback(() => {
        const periodDuration = getPeriodDuration(period)
        const previousStartDate = moment(startDate).subtract(1, periodDuration).toDate()
        const previousEndDate = moment(endDate).subtract(1, periodDuration).toDate()

        setStartDate(previousStartDate)
        setEndDate(previousEndDate)
    }, [endDate, getPeriodDuration, period, startDate])

    const handleNextPeriod = useCallback(() => {
        const periodDuration = getPeriodDuration(period)
        const nextStartDate = moment(startDate).add(1, periodDuration).toDate()
        const nextEndDate = moment(endDate).add(1, periodDuration).toDate()

        setStartDate(nextStartDate)
        setEndDate(nextEndDate)
    }, [endDate, getPeriodDuration, period, startDate])

    const determinePeriod = useCallback((start: Date | null, end: Date | null) => {
        if (!start || !end) return FilterSelect.ALL

        const startMoment = moment(start)
        const endMoment = moment(end)

        // Проверка на день
        const isSameDay = startMoment.isSame(endMoment, 'day')
        if (isSameDay) {
            return FilterSelect.DAY
        }

        // Проверка на неделю
        const isSameWeek = startMoment.isSame(endMoment, 'week')
        if (isSameWeek) {
            return FilterSelect.WEEK
        }

        // Проверка на месяц
        const isSameMonth = startMoment.isSame(endMoment, 'month')
        if (
            isSameMonth &&
            startMoment.isSame(startMoment.startOf('month'), 'day') &&
            endMoment.isSame(endMoment.endOf('month'), 'day')
        ) {
            return FilterSelect.MONTH
        }

        // Проверка на квартал
        const isSameQuarter = startMoment.isSame(endMoment, 'quarter')
        if (
            isSameQuarter &&
            startMoment.isSame(startMoment.startOf('quarter'), 'day') &&
            endMoment.isSame(endMoment.endOf('quarter'), 'day')
        ) {
            return FilterSelect.QUARTER
        }

        // Проверка на год
        const isSameYear = startMoment.isSame(endMoment, 'year')
        if (
            isSameYear &&
            startMoment.isSame(startMoment.startOf('year'), 'day') &&
            endMoment.isSame(endMoment.endOf('year'), 'day')
        ) {
            return FilterSelect.YEAR
        }

        return FilterSelect.ALL
    }, [])

    const buttonText = useMemo(
        () =>
            ({
                [FilterSelect.ALL]: null,
                [FilterSelect.YEAR]: moment(startDate).format('YYYY год'),
                [FilterSelect.QUARTER]: moment(startDate).format('Q Квартал YYYY'),
                [FilterSelect.MONTH]: ` Месяц: ${moment(startDate).format('MMMM YYYY')}`,
                [FilterSelect.WEEK]: `Неделя: ${moment(startDate).format(
                    'DD MMM YYYY',
                )}-${moment(endDate).format('DD MMM YYYY')}`,
                [FilterSelect.DAY]: `${moment(startDate).format('DD MMMM')}`,
            })[period],
        [endDate, period, startDate],
    )

    useEffect(() => {
        applyFilters.dateRange(startDate, endDate)
    }, [startDate, endDate, dispatch, applyFilters])

    // Статистика продаж
    const [profit, setProfit] = useState(0)
    const [soldParts, setSoldParts] = useState(0)
    const [averageCheck, setAverageCheck] = useState(0)
    const userSummary = useSelector(state => state.sales.userSummary)

    useEffect(() => {
        setProfit(userSummary.soldSum)
        setSoldParts(userSummary.soldCount)
        setAverageCheck(Math.round(userSummary.soldSum / userSummary.soldCount))
    }, [userSummary])

    useEffect(() => {
        if (!isSearchParamsLoaded || isLoadingDocuments) return
        const { partsId } = documents.find(document => document.value === documentId) || {}
        dispatch(getSummaryByUserId(dateRange, marketplace, partsId))
    }, [isSearchParamsLoaded, isLoadingDocuments])

    // Главный поиск
    const setNameSearch = useCallback(
        (searchTerm: string) => {
            dispatch({
                type: storeDispatchTypes.setPartNameTextFilterParts,
                value: {
                    partName: searchTerm,
                },
            })
        },
        [dispatch],
    )

    const setCurrentPart = useCallback(
        (currentPart?: Part) => {
            dispatch({
                type: storeDispatchTypes.setCurrentPart,
                value: currentPart,
            })
        },
        [dispatch],
    )

    const navigateWithParams = useCallback(
        (partId = '') => {
            const queryParams = new URLSearchParams(location.search)

            if (partId) {
                if (params.partId !== partId) {
                    navigate(`/admin/parts/sold/${partId}?${queryParams.toString()}`)
                }
            } else {
                navigate(`/admin/parts/sold?${queryParams.toString()}`)
            }
        },
        [navigate, params.partId],
    )

    const openPartModal = useCallback(
        (part: Part) => {
            navigateWithParams(part.id)
            setIsPartModalOpen(true)
            setCurrentPart(part)
        },
        [navigateWithParams, setCurrentPart],
    )

    const handleClose = useCallback(() => {
        navigateWithParams()
        setIsPartModalOpen(false)
    }, [navigateWithParams])

    const getPartById = useCallback(async () => {
        if (params.partId) {
            const part = await DatahubApiService.getPartByPartId(params.partId, false, true)

            if (!part) {
                return
            } else {
                setCurrentPart(part)
                setIsPartModalOpen(true)
            }
        }
    }, [params.partId, setCurrentPart])

    // open modal by partId
    useEffect(() => {
        if (params.partId) {
            getPartById()
        } else {
            setIsPartModalOpen(false)
        }
    }, [getPartById, params.partId])

    // Сброс фильтров при уходе со страницы
    useResetFiltersOnUnmount()

    return (
        <div className={styles.wrap}>
            {currentPart && isPartModalOpen && (
                <ModalViewPart part={currentPart} isOpen={isPartModalOpen} closeModal={() => handleClose()} />
            )}
            <div className={styles.rowWrap}>
                <SalesText
                    text={'Проданные запчасти'}
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.xxl}
                    letterSpacing={-0.02}
                />
            </div>
            <div className={styles.rowWrap}>
                <SearchComponent oldSearchTerm={partName} setSearchTerm={setNameSearch} />
            </div>
            <div className={styles.rowWrap}>
                <NavigationButtons
                    pages={pagesParts}
                    currentPage={getAdminNavigationPath(adminRouteAlias.soldParts.location)}
                    counts={counts}
                    navigate={navigate}
                />
                <Dropdown className={styles.customDropdown}>
                    <Dropdown.Toggle>{marketplace ? Marketplaces[marketplace] : 'Выбрать маркетплейс'}</Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => filterPartsByMarketplace(null)}>Все</Dropdown.Item>
                        {Object.entries(Marketplaces).map(([key, value]) => (
                            <Dropdown.Item key={key} onClick={() => filterPartsByMarketplace(key as MarketplacesKeys)}>
                                {value}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className={styles.sortControls}>
                {Object.entries(FilterSelect).map(([key, value]) => (
                    <SalesButton
                        key={key}
                        borderRadius={10}
                        backgroundColor={value === period ? colors.grayDark : undefined}
                        paddingHorizontalPixels={12}
                        paddingVerticalPixels={7}
                        onClick={() => handlePeriodChange(value)}
                    >
                        <SalesText
                            text={value}
                            fontSize={fontSizes.s}
                            color={value === period ? colors.white : undefined}
                        />
                    </SalesButton>
                ))}

                {carFilter && (
                    <Link
                        to={`${getAdminNavigationPath(adminRouteAlias.cars.location)}/${carFilter?.carId}`}
                        style={{ textDecoration: 'none' }}
                    >
                        <SalesButton borderRadius={10} paddingHorizontalPixels={20} paddingVerticalPixels={7}>
                            <SalesText
                                text={`Перейти к авто ${carFilter?.markTitle} ${carFilter?.modelTitle} ${carFilter?.year}`}
                                fontSize={fontSizes.s}
                            />
                        </SalesButton>
                    </Link>
                )}

                <div className={'ms-auto'}>
                    <Form.Select value={documentId ? documentId : ''} onChange={handleSelectChangeDocument}>
                        <option value={''}>Выбрать накладную</option>
                        {documents.map(document => (
                            <option key={document.value} value={document.value}>
                                {document.name}
                            </option>
                        ))}
                    </Form.Select>
                </div>
            </div>
            {buttonText && (
                <div className={styles.sortControls}>
                    <div className={styles.sortControls}>
                        <SalesButton
                            borderRadius={10}
                            backgroundColor={colors.grayDark}
                            paddingHorizontalPixels={12}
                            paddingVerticalPixels={7}
                            onClick={handlePreviousPeriod}
                        >
                            <ChevronLeft size={18} color={colors.white} />
                        </SalesButton>
                        <SalesButton
                            borderRadius={10}
                            backgroundColor={colors.grayDark}
                            paddingHorizontalPixels={12}
                            paddingVerticalPixels={7}
                            onClick={() => handlePeriodChange(period)}
                        >
                            <SalesText text={buttonText} fontSize={fontSizes.s} color={colors.white} />
                        </SalesButton>
                        <SalesButton
                            borderRadius={10}
                            backgroundColor={colors.grayDark}
                            paddingHorizontalPixels={12}
                            paddingVerticalPixels={7}
                            onClick={handleNextPeriod}
                            disabled={moment(endDate).isSameOrAfter(new Date(), 'day')}
                        >
                            <ChevronRight size={18} color={colors.white} />
                        </SalesButton>
                    </div>
                </div>
            )}
            <div className={styles.statiscticsBlock}>
                {!!parts.length && canShowFinanceInfo && (
                    <>
                        <p className={styles.statiscticsBlockText}>
                            Выручка за период: <b>{profit.toLocaleString('ru-RU')}</b>{' '}
                            {getCurrencySymbol(currentCurrency)}
                        </p>
                        <p className={styles.statiscticsBlockText}>
                            Продано запчастей: <b>{soldParts.toLocaleString('ru-RU')}</b>
                        </p>
                        <p className={styles.statiscticsBlockText}>
                            Средний чек: <b>{averageCheck.toLocaleString('ru-RU')}</b>{' '}
                            {getCurrencySymbol(currentCurrency)}
                        </p>
                    </>
                )}
                {!isLoading && parts.length === 0 ? (
                    <></>
                ) : (
                    <div className={styles.wrapRight}>
                        <ListPagination
                            page={page}
                            setPage={page =>
                                dispatch({
                                    type: storeDispatchTypes.setPageFilterParts,
                                    value: { page },
                                })
                            }
                            perPage={perPage}
                            itemsCount={itemsCount}
                        />
                    </div>
                )}
            </div>

            <Table striped hover>
                <thead>
                    <tr>
                        <th scope="col" style={colWidth(54)}>
                            <TableHeadCategorySearchParts />
                        </th>
                        <th scope="col" style={colWidth(204)}>
                            <TableHeadPartSearch oldSearchTerm={partName} />
                        </th>
                        <th scope="col" style={colWidth(100)} className={`${carFilter ? styles.filtered : ''}`}>
                            {carFilter ? carFilter.markTitle : <TableHeadMarkSearchParts />}
                        </th>
                        <th scope="col" style={colWidth(200)} className={`${carFilter ? styles.filtered : ''}`}>
                            {carFilter ? carFilter.modelTitle : <TableHeadModelSearchParts />}
                        </th>
                        <th scope="col" style={colWidth(35)}>
                            Фото
                        </th>
                        <th scope="col" style={colWidth(135)}>
                            Артикул
                            {filterVendorCode ? (
                                <ResetFilter filterName="filterVendorCode" />
                            ) : (
                                <ColumnSort
                                    iconType={SortIconType.NUMERIC}
                                    sortField={SortFieldParts.VENDOR_CODE}
                                    slice={'partList'}
                                    reverseSort={true}
                                />
                            )}
                        </th>
                        <th scope="col" style={colWidth(103)}>
                            <TableHeadPartNumberSearchParts />
                        </th>
                        <th scope="col" style={colWidth(35)}>
                            Кол.
                        </th>
                        <th scope="col" style={colWidth(95)}>
                            <TableHeadRangeFilter
                                initialTableHead={'Цена продажи'}
                                step={1000}
                                leftInPixes={-35}
                                initialFromVal={priceFrom}
                                initialToVal={priceTo}
                                widthInPixels={150}
                                storeActionSetRange={storeDispatchTypes.setPriceRangeParts}
                            />
                        </th>
                        <th scope="col" style={colWidth(85)}>
                            Дата
                            <ColumnSort
                                iconType={SortIconType.NUMERIC}
                                sortField={SortFieldParts.SOLD_AT}
                                slice={'partList'}
                                reverseSort={true}
                            />
                        </th>
                        <th scope="col" style={colWidth(99)}>
                            Действия
                        </th>
                    </tr>
                </thead>
                {isLoading || !parts ? (
                    <ListSkeleton rowCount={9} columnCount={11} marginVerticalRem={0.21} />
                ) : (
                    <tbody>
                        {parts.map((part, index) => (
                            <tr key={index} className={styles.row}>
                                <td scope="row" onClick={() => openPartModal(part)}>
                                    <CategoryIcon categoryId={part.groupId} />
                                </td>
                                <td scope="row" onClick={() => openPartModal(part)}>
                                    {part.customTitle ? part.customTitle : part.title}
                                </td>
                                <td scope="row" onClick={() => openPartModal(part)}>
                                    {part.markTitle}
                                </td>
                                <td scope="row">{part.modelTitle}</td>
                                <td scope="row" className={styles.imageRow}>
                                    <CellThumbCarousel pictures={part.images} />
                                </td>
                                <td scope="row" className={styles.default}>
                                    <CopyButton
                                        copyText={part.vendorCodePrefix + part.vendorCode}
                                        overlayText={'Артикул скопирован в буфер обмена'}
                                        isText
                                    />
                                </td>
                                <td scope="row" className={styles.default}>
                                    <CopyButton
                                        copyText={part.partNumber}
                                        overlayText={'ОЕМ скопирован в буфер обмена'}
                                        isText
                                    />
                                </td>
                                <td scope="row" onClick={() => openPartModal(part)}>
                                    {!part.extraData?.soldQtyArr
                                        ? 1
                                        : part.isSold
                                          ? part.extraData?.soldQtyArr.length + 1
                                          : part.extraData?.soldQtyArr.length}
                                </td>
                                <td scope="row" className={styles.alignRight} onClick={() => openPartModal(part)}>
                                    {!part.extraData?.soldQtyArr
                                        ? part.price
                                        : part.isSold
                                          ? part.price +
                                            part.extraData.soldQtyArr.reduce(
                                                (summ, current) => summ + (current.price ? current.price : 0),
                                                0,
                                            )
                                          : part.extraData.soldQtyArr.reduce(
                                                (summ, current) => summ + (current.price ? current.price : 0),
                                                0,
                                            )}
                                </td>
                                <td scope="row" className={styles.alignRight}>
                                    <div>
                                        <Badge text={formatDate(part.soldAt)} color="#ddd" fontColor="#555" />
                                    </div>
                                    {+countDaysInStock(`${part.soldAt}`) > 10 ? (
                                        <div>
                                            <Badge
                                                text={
                                                    countDaysInStock(`${part.soldAt}`) +
                                                    ' ' +
                                                    num_word(+countDaysInStock(`${part.soldAt}`), [
                                                        'день',
                                                        'дня',
                                                        'дней',
                                                    ])
                                                }
                                                color="#eee"
                                                fontColor="#777"
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </td>
                                <td className={styles.default}>
                                    <CellActionsParts part={part} isSafari={browserName === 'Safari'} isSoldPartList />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}
            </Table>
            {!isLoading && parts.length === 0 ? (
                <ListNoItems slice={'partList'} />
            ) : (
                <ListPagination
                    page={page}
                    setPage={page =>
                        dispatch({
                            type: storeDispatchTypes.setPageFilterParts,
                            value: { page },
                        })
                    }
                    perPage={perPage}
                    itemsCount={itemsCount}
                    justifyContent={'flex-end'}
                />
            )}
        </div>
    )
}

export default SoldPartList
