import { Dispatch } from 'redux'

import { SelectOptionLocal } from '../../../types/common/commonTypes'
import { apiInstance } from '../../api/apiInstance'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getAllAudioSizes =
    (searchTerm: string) =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'audioSizes', value: true } })

        try {
            const {
                data: { status, data },
            } = await apiInstance.post('/dicts/avito/AudioSizes')
            const audioSizes: SelectOptionLocal[] = []

            if (status === 'ok') {
                const parser = new DOMParser()
                const xmlDoc = parser.parseFromString(data, 'text/xml')
                const sizesNodes = xmlDoc.getElementsByTagName('Size')

                for (let i = 0; i < sizesNodes.length; i++) {
                    const sizeName = sizesNodes[i].textContent?.trim()
                    if (sizeName && sizeName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                        audioSizes.push({ name: sizeName, value: sizeName })
                    }
                }
            }
            dispatch({ type: storeDispatchTypes.setAllAudioSizes, value: { audioSizes } })
        } catch (e) {
            console.error('load audioSizes failed', e)
        }

        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'audioSizes', value: false } })
    }

export default getAllAudioSizes
