import React, { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap'

import styles from './styles.module.scss'

interface IProps {
    page: number
    setPage: (value: number) => void
    perPage: number
    itemsCount: number
    increment?: number
    justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
}

const getStartPage = (page: number, pageCount: number, increment: number) => {
    if (page - increment <= 1) {
        return 1
    } else if (page + increment >= pageCount) {
        return pageCount - increment * 2 > 0 ? pageCount - increment * 2 : 1
    }
    return page - increment
}

const getEndPage = (page: number, pageCount: number, increment: number) => {
    if (page + increment >= pageCount) {
        return pageCount
    } else if (page - increment <= 1) {
        return 1 + increment * 2 <= pageCount ? 1 + increment * 2 : pageCount
    }
    return page + increment
}

const ListPagination = ({ page, setPage, perPage, itemsCount, increment = 1, justifyContent }: IProps) => {
    const pageCount = Math.ceil(itemsCount / perPage)

    const [startPage, setStartPage] = useState<number>(() => getStartPage(page, pageCount, increment))
    const [endPage, setEndPage] = useState<number>(() => getEndPage(page, pageCount, increment))

    const goPrevious = () => page > 1 && setPage(page - 1)
    const goNext = () => page < pageCount && setPage(page + 1)
    const goFirst = () => setPage(1)
    const goLast = () => setPage(pageCount)

    const placeholderButtons = Array.from({ length: 3 }).map((_, index) => (
        <Pagination.Item key={index + 1}>{index + 1}</Pagination.Item>
    ))

    const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(num => (
        <Pagination.Item key={num} active={num === page} onClick={() => setPage(num)}>
            {num}
        </Pagination.Item>
    ))

    useEffect(() => {
        setStartPage(getStartPage(page, pageCount, increment))
        setEndPage(getEndPage(page, pageCount, increment))
    }, [page, pageCount, increment])

    return (
        <Pagination className={styles.customPagination} style={{ justifyContent }}>
            <Pagination.First onClick={goFirst} disabled={page === 1} />
            <Pagination.Prev onClick={goPrevious} disabled={page === 1} />
            {visiblePages.length === 0 ? placeholderButtons : visiblePages}
            <Pagination.Next onClick={goNext} disabled={page === pageCount} />
            <Pagination.Last onClick={goLast} disabled={page === pageCount} />
        </Pagination>
    )
}

export default ListPagination
