import React, { useEffect, useState } from 'react'
import { ChevronRight } from 'react-bootstrap-icons'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import SalesButton from '../../components/_atoms/SalesButton/SalesButton'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import ModalMarketplace from '../../components/_molecules/ModalMarketplace/ModalMarketplace'
import SalesButtonStandard from '../../components/_molecules/SalesButtonStandard/SalesButtonStandard'
import SalesCardHighlights from '../../components/_molecules/SalesCardHighlights/SalesCardHighlights'
import SalesCardMarketPlaces from '../../components/_molecules/SalesCardMarketPlaces/SalesCardMarketPlaces'
import SalesCardShop from '../../components/_molecules/SalesCardShop/SalesCardShop'
import SalesUserSummaryText from '../../components/_molecules/SalesUserSummaryText/SalesUserSummaryText'
import { colors, fontSizes, fontWeights } from '../../constants/salesStyles'
import getSummaryByUserId from '../../modules/redux/sales/getSummaryByUserId'
import { useSelector } from '../../modules/store/customSelector'
import { adminRouteAlias, getAdminNavigationPath } from '../../router/adminRouteAlias'

import SalesChart from './Charts'
import styles from './styles.module.scss'

const Sales = () => {
    // placeholders START
    const title = 'Продажи'
    // placeholders END

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userSummary = useSelector(state => state.sales.userSummary)
    const canShowFinanceInfo = useSelector(state => state.userData.perms?.canShowFinanceInfo)
    const [marketplacesLimit] = useState<number | undefined>(3)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [marketplace, setMarketplace] = useState<string | null>(null)

    const openModal = (marketplace: string) => {
        setIsModalOpen(true)
        setMarketplace(marketplace)
    }

    useEffect(() => {
        dispatch(getSummaryByUserId())
    }, [])

    return (
        <div className={styles.wrap}>
            {isModalOpen && marketplace && (
                <ModalMarketplace
                    isOpen={isModalOpen}
                    closeModal={() => setIsModalOpen(false)}
                    marketplace={marketplace}
                />
            )}
            <div className={styles.contentWrap}>
                <SalesText text={title} fontWeight={fontWeights.bold} fontSize={fontSizes.xxl} letterSpacing={-0.02} />
                <div className={styles.subtitleWrap}>
                    {userSummary.soldSum > 0 ? (
                        <>
                            {canShowFinanceInfo && (
                                <>
                                    <SalesUserSummaryText field={'soldCount'} value={userSummary.soldCount} />
                                    <SalesUserSummaryText field={'soldSum'} value={userSummary.soldSum} isLast />
                                </>
                            )}
                            <SalesButton
                                borderRadius={10}
                                backgroundColor={colors.blueLight}
                                paddingHorizontalPixels={20}
                                paddingVerticalPixels={6}
                                marginLeftPixels={canShowFinanceInfo ? 20 : 0}
                                onClick={() => navigate(getAdminNavigationPath(adminRouteAlias.soldParts.location))}
                            >
                                <>
                                    <SalesText
                                        text={'Статистика продаж'}
                                        marginRightPixels={10}
                                        fontSize={fontSizes.s}
                                        color={colors.white}
                                    />
                                    <ChevronRight size={18} color={colors.white} />
                                </>
                            </SalesButton>
                        </>
                    ) : (
                        <SalesText
                            text={`пока ничего не продано`}
                            fontWeight={fontWeights.semiBold}
                            fontSize={fontSizes.l}
                            color={colors.blue}
                            marginTopPixels={8}
                            marginBottomPixels={30}
                        />
                    )}
                </div>
                {canShowFinanceInfo && <SalesChart />}
                {canShowFinanceInfo && (
                    <div className={styles.subtitleWrap}>
                        {userSummary.totalSum > 0 && (
                            <SalesUserSummaryText
                                field={'totalSum'}
                                value={userSummary.totalSum}
                                prefix="Весь склад:"
                            />
                        )}
                        {userSummary.totalCount > 0 && (
                            <SalesUserSummaryText
                                field={'totalCount'}
                                value={userSummary.totalCount}
                                prefix="Товарные позиции:"
                                isLast
                            />
                        )}
                        {userSummary.totalCount !== userSummary.totalCountWithQty && (
                            <SalesUserSummaryText
                                field={'totalCountWithQty'}
                                value={userSummary.totalCountWithQty}
                                prefix=", кол-во:"
                                isLast
                            />
                        )}
                    </div>
                )}
            </div>

            <div className={styles.cardsBackground}>
                <div className={styles.contentWrap}>
                    <div className={styles.cardsWrap}>
                        <SalesCardHighlights />

                        <SalesCardMarketPlaces
                            limit={marketplacesLimit}
                            openModal={openModal}
                            footerContent={
                                <>
                                    <SalesButtonStandard
                                        text={'Все маркетплейсы и контакты'}
                                        backgroundColor={colors.white}
                                        marginRightPixels={20}
                                        onClick={() =>
                                            navigate(getAdminNavigationPath(adminRouteAlias.marketplaces.location))
                                        }
                                    />
                                </>
                            }
                        />

                        <SalesCardShop />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sales
