import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

import checkPerms from '../../modules/redux/userSettings/CheckPerms'
import { useDispatch } from '../../modules/store/customDispatch'
import { useSelector } from '../../modules/store/customSelector'
import { adminRouteAlias } from '../../router/adminRouteAlias'

export const useCheckPerms = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const canShowFinanceInfo = useSelector(state => state.userData.perms?.canShowFinanceInfo)

    useEffect(() => {
        dispatch(checkPerms())
    }, [dispatch])

    useEffect(() => {
        const restrictedPaths = [
            // adminRouteAlias.sales.location,
            adminRouteAlias.documents.location,
            // adminRouteAlias.soldParts.location,
        ]

        const isRestrictedPage = restrictedPaths.some(path => location.pathname.includes(path))

        if (!canShowFinanceInfo && isRestrictedPage) {
            navigate('/', { replace: true })
        }
    }, [canShowFinanceInfo, location.pathname, navigate])
}
