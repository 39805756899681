import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getCarsSelectSearchCarTitle =
    (searchTerm: string) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { userData } = getState()
        const params = {
            userId: userData.smsAuth.userId,
            search: searchTerm,
        }
        const config = {
            headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` },
        }
        dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'cars', value: true } })
        try {
            const {
                data: { status, carTitlesList },
            } = await apiInstance.post('/getCarsSelectSearchCarTitle', params, config)
            if (status === 'ok') {
                dispatch({
                    type: storeDispatchTypes.setCars,
                    value: {
                        cars: carTitlesList,
                    },
                })
            }
        } catch (e) {
            console.error('load cars title failed', e)
        }
        dispatch({ type: storeDispatchTypes.seCarsLoader, value: { field: 'cars', value: false } })
    }

export default getCarsSelectSearchCarTitle
