import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const setPartPrice =
    (partId: string, newPrice: number) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<boolean> => {
        const { userData } = getState()

        dispatch({
            type: storeDispatchTypes.setPartPriceLoading,
            value: true,
        })

        try {
            const {
                data: { status },
            } = await apiInstance.post(
                '/setPartPrice',
                {
                    userId: userData.smsAuth.userId,
                    partId,
                    newPrice,
                },
                {
                    headers: {
                        Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
                    },
                },
            )
            if (status === 'ok') {
                dispatch({
                    type: storeDispatchTypes.updatePartPrice,
                    value: {
                        id: partId,
                        price: newPrice,
                    },
                })
                return true
            }
            return false
        } catch (e) {
            console.error('set part price failed', e)
            return false
        } finally {
            dispatch({
                type: storeDispatchTypes.setPartPriceLoading,
                value: false,
            })
        }
    }

export default setPartPrice
