import React from 'react'
import { Table, Form } from 'react-bootstrap'
import { CheckCircleFill, Printer } from 'react-bootstrap-icons'
import { browserName } from 'react-device-detect'
import { useDispatch } from 'react-redux'

import classNames from 'classnames'

import { colors, fontSizes } from '../../../constants/salesStyles'
import StickersApiService from '../../../modules/api/StickersApiService'
import { AppDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import { SortFieldParts, SortIconType } from '../../../types/common/commonTypes'
import { Part } from '../../../types/part/partTypes'
import { countDaysInStock, formatDate, num_word } from '../../../utils/converters'
import { colWidth } from '../../../utils/stylePatches'
import Badge from '../../_atoms/Badge/Badge'
import CategoryIcon from '../../_atoms/CategogyIcon/CategoryIcon'
import CellEditPrice from '../../_atoms/CellEditPrice/CellEditPrice'
import CopyButton from '../../_atoms/CopyButton/CopyButton'
import ListSkeleton from '../../_atoms/ListSkeleton/ListSkeleton'
import TableHeadPartSearch from '../../_atoms/TableHeadPartSearch/TableHeadPartSearch'
import ColumnSort from '../../_molecules/ColumnSort/ColumnSort'
import ResetFilter from '../../_molecules/ResetFilter/ResetFilter'
import TableHeadCategorySearchParts from '../../_molecules/TableHeadCategorySearchParts/TableHeadCategorySearchParts'
import TableHeadMarkSearchParts from '../../_molecules/TableHeadMarkSearchParts/TableHeadMarkSearchParts'
import TableHeadModelSearchParts from '../../_molecules/TableHeadModelSearchParts/TableHeadModelSearchParts'
import TableHeadPartNumberSearchParts from '../../_molecules/TableHeadPartNumberSearchParts/TableHeadPartNumberSearchParts'
import TableHeadPriceRangeParts from '../../_molecules/TableHeadPriceRangeParts/TableHeadPriceRangeParts'
import CellActionsParts from '../CellActionsParts/CellActionsParts'
import CellQuantityParts from '../CellQuantityParts/CellQuantityParts'
import CellThumbCarousel from '../CellThumbCarousel/CellThumbCarousel'

import styles from './styles.module.scss'

interface Column {
    id: string
    label: string
}

interface RenderTableProps {
    columns: Column[]
    parts: Part[]
    isLoading: boolean
    selectAll: boolean
    selectedParts: Part[]
    handleCheckboxChange: (part: Part) => void
    openEditModal: (part: Part) => void
}

const TablePartList = ({
    columns,
    parts,
    isLoading,
    selectAll,
    selectedParts,
    handleCheckboxChange,
    openEditModal,
}: RenderTableProps) => {
    const dispatch = useDispatch<AppDispatch>()

    // Сохранилась ли запчасть успешно или с ошибкой
    const isPartSavedSuccessfully = useSelector(state => state.partList.isPartSavedSuccessfully)

    // search filter part title
    const partName = useSelector(state => state.partList.filters.partName)
    // Car filter
    const carFilter = useSelector(state => state.partList.filters.car)
    // Range Filters
    const priceFrom = useSelector(state => state.partList.filters.priceRange?.from)
    const priceTo = useSelector(state => state.partList.filters.priceRange?.to)
    const filterDate = useSelector(state => state.partList.filters.filterDate)
    const filterDateField = useSelector(state => state.partList.filters.filterDateField)
    const filterVendorCode = useSelector(state => state.partList.filters.filterVendorCode)

    const isPartHaveWarehouse = (part: Part) => {
        const warehouse = part.extraData?.warehouse
        return warehouse && Object.keys(warehouse).length > 0
    }

    const filterPartsByDate = (date: string) => {
        dispatch({
            type: storeDispatchTypes.setFilterDateFilterParts,
            value: {
                filterDate: date,
            },
        })
    }

    const filterPartsByDateField = (field: string) => {
        dispatch({
            type: storeDispatchTypes.setFilterDateFieldFilterParts,
            value: {
                filterDateField: field,
            },
        })
    }

    const handleAllCheckboxChange = () => {
        const newSelectAll = !selectAll
        dispatch({
            type: storeDispatchTypes.setSelectAllParts,
            value: newSelectAll,
        })
    }

    const printPart = (part: Part) => {
        const url = StickersApiService.getEncodeUrlFromPart(part)
        if (url) window.open(url, '_blank')
    }

    return (
        <Table striped hover>
            <thead>
                <tr>
                    <th scope="col" style={colWidth(35)} className={styles.alignCenter}>
                        <Form.Check>
                            <Form.Check.Input
                                className={styles.checkboxLg}
                                checked={selectAll}
                                onChange={handleAllCheckboxChange}
                            />
                        </Form.Check>
                    </th>
                    {columns.map(column => {
                        switch (column.id) {
                            case 'groupId':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(80)}>
                                        <TableHeadCategorySearchParts />
                                    </th>
                                )
                            case 'customTitle':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(204)}>
                                        <TableHeadPartSearch oldSearchTerm={partName} />
                                    </th>
                                )
                            case 'markTitle':
                                return (
                                    <th
                                        key={column.id}
                                        scope="col"
                                        style={colWidth(100)}
                                        className={classNames({ [styles.filtered]: carFilter })}
                                    >
                                        {carFilter ? carFilter.markTitle : <TableHeadMarkSearchParts />}
                                    </th>
                                )
                            case 'modelTitle':
                                return (
                                    <th
                                        key={column.id}
                                        scope="col"
                                        style={colWidth(200)}
                                        className={`${carFilter ? styles.filtered : ''}`}
                                    >
                                        {carFilter ? carFilter.modelTitle : <TableHeadModelSearchParts />}
                                    </th>
                                )
                            case 'photo':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(35)}>
                                        Фото
                                    </th>
                                )
                            case 'price':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(95)}>
                                        <TableHeadPriceRangeParts initialFromVal={priceFrom} initialToVal={priceTo} />
                                    </th>
                                )
                            case 'pricePurchase':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(100)}>
                                        Цена закупки
                                    </th>
                                )
                            case 'accepted':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(65)}>
                                        Принято
                                    </th>
                                )
                            case 'vendorCode':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(135)}>
                                        Артикул
                                        {filterVendorCode ? (
                                            <ResetFilter filterName="filterVendorCode" />
                                        ) : (
                                            <ColumnSort
                                                iconType={SortIconType.NUMERIC}
                                                sortField={SortFieldParts.VENDOR_CODE}
                                                slice={'partList'}
                                                reverseSort={true}
                                            />
                                        )}
                                    </th>
                                )
                            case 'partNumber':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(103)}>
                                        <TableHeadPartNumberSearchParts />
                                    </th>
                                )
                            case 'createdAt':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(85)} className={styles.alignRight}>
                                        Дата
                                        {filterDate && filterDateField === SortFieldParts.CREATED_AT ? (
                                            <ResetFilter filterName="filterDate" />
                                        ) : (
                                            <ColumnSort
                                                iconType={SortIconType.NUMERIC}
                                                sortField={SortFieldParts.CREATED_AT}
                                                slice={'partList'}
                                                reverseSort={true}
                                            />
                                        )}
                                    </th>
                                )
                            case 'updatedAt':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(85)} className={styles.alignRight}>
                                        Ред.
                                        {filterDate && filterDateField === SortFieldParts.UPDATED_AT ? (
                                            <ResetFilter filterName="filterDate" />
                                        ) : (
                                            <ColumnSort
                                                iconType={SortIconType.NUMERIC}
                                                sortField={SortFieldParts.UPDATED_AT}
                                                slice={'partList'}
                                                reverseSort={true}
                                            />
                                        )}
                                    </th>
                                )
                            case 'quantity':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(60)}>
                                        Кол.
                                    </th>
                                )
                            case 'actions':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(80)}>
                                        Действия
                                    </th>
                                )
                            case 'position':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(80)}>
                                        Расположение
                                    </th>
                                )
                            case 'warehousePlace':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(80)}>
                                        Место
                                    </th>
                                )
                            case 'sticker':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(40)}>
                                        QR
                                    </th>
                                )
                            case 'partId':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(100)}>
                                        Avito Id
                                    </th>
                                )
                            case 'incomingInvoiceTitle':
                                return (
                                    <th key={column.id} scope="col" style={colWidth(100)}>
                                        Приход Н
                                    </th>
                                )
                            default:
                                return (
                                    <td key={column.id} scope="row">
                                        Неизвестный столбец
                                    </td>
                                )
                        }
                    })}
                </tr>
            </thead>

            {isLoading || !parts ? (
                <ListSkeleton rowCount={9} columnCount={columns.length + 1} marginVerticalRem={0.21} />
            ) : (
                <tbody className={styles.body}>
                    {parts.map((part, index) => (
                        <tr key={index} className={styles.row}>
                            <td scope="row" className={styles.alignCenter}>
                                <Form.Check>
                                    <Form.Check.Input
                                        className={styles.checkboxLg}
                                        checked={selectedParts.some(p => p.id === part.id) || selectAll}
                                        onChange={() => handleCheckboxChange(part)}
                                        disabled={selectAll}
                                    />
                                </Form.Check>
                            </td>
                            {columns.map(column => {
                                switch (column.id) {
                                    case 'groupId':
                                        return (
                                            <td key={column.id} scope="row" onClick={() => openEditModal(part)}>
                                                <CategoryIcon categoryId={part.groupId} />
                                            </td>
                                        )
                                    case 'customTitle':
                                        return (
                                            <td key={column.id} scope="row" onClick={() => openEditModal(part)}>
                                                {part.customTitle ? part.customTitle : part.title}
                                            </td>
                                        )
                                    case 'markTitle':
                                        return (
                                            <td key={column.id} scope="row" onClick={() => openEditModal(part)}>
                                                {part.markTitle}
                                            </td>
                                        )
                                    case 'modelTitle':
                                        return (
                                            <td key={column.id} scope="row" onClick={() => openEditModal(part)}>
                                                {part.modelTitle}
                                                {part.modificationTitle ? (
                                                    <div>
                                                        <Badge
                                                            text={part.modificationTitle}
                                                            color="#ddd"
                                                            fontColor="#555"
                                                        />
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                {part.carVinCode ? (
                                                    <div>
                                                        <Badge text={'vin: ' + part.carVinCode} color="#aaa" />
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        )
                                    case 'photo':
                                        return (
                                            <td key={column.id} scope="row" className={styles.imageRow}>
                                                <CellThumbCarousel pictures={part.images} part={part} />
                                            </td>
                                        )
                                    case 'price':
                                        return (
                                            <td key={column.id} scope="row">
                                                <CellEditPrice id={part.id} price={part.price} />
                                            </td>
                                        )
                                    case 'pricePurchase':
                                        return (
                                            <td key={column.id} scope="row">
                                                <CellEditPrice
                                                    id={part.id}
                                                    price={part.extraData?.pricePurchase}
                                                    isPricePurchase
                                                />
                                            </td>
                                        )
                                    case 'accepted':
                                        return (
                                            <td key={column.id} scope="row" className={styles.alignCenter}>
                                                {isPartHaveWarehouse(part) && (
                                                    <CheckCircleFill color={colors.greenDark} size={18} />
                                                )}
                                            </td>
                                        )
                                    case 'vendorCode':
                                        return (
                                            <td key={column.id} scope="row" className={styles.default}>
                                                <CopyButton
                                                    copyText={part.vendorCodePrefix + part.vendorCode}
                                                    overlayText={'Артикул скопирован в буфер обмена'}
                                                    isText
                                                />
                                            </td>
                                        )
                                    case 'partNumber':
                                        return (
                                            <td key={column.id} scope="row" className={styles.default}>
                                                <CopyButton
                                                    copyText={part.partNumber}
                                                    overlayText={'ОЕМ скопирован в буфер обмена'}
                                                    isText
                                                />
                                            </td>
                                        )
                                    case 'createdAt':
                                        return (
                                            <td
                                                key={column.id}
                                                scope="row"
                                                onClick={() => {
                                                    filterPartsByDate(formatDate(part.createdAt))
                                                    filterPartsByDateField(SortFieldParts.CREATED_AT)
                                                }}
                                                className={styles.alignRight}
                                            >
                                                <div>
                                                    <Badge
                                                        text={formatDate(part.createdAt)}
                                                        color="#ddd"
                                                        fontColor="#555"
                                                    />
                                                </div>
                                                {+countDaysInStock(part.createdAt) > 10 ? (
                                                    <div>
                                                        <Badge
                                                            text={
                                                                countDaysInStock(part.createdAt) +
                                                                ' ' +
                                                                num_word(+countDaysInStock(part.createdAt), [
                                                                    'день',
                                                                    'дня',
                                                                    'дней',
                                                                ])
                                                            }
                                                            color="#eee"
                                                            fontColor="#777"
                                                        />
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        )
                                    case 'updatedAt':
                                        return (
                                            <td
                                                key={column.id}
                                                scope="row"
                                                onClick={() => {
                                                    filterPartsByDate(formatDate(part.updatedAt))
                                                    filterPartsByDateField(SortFieldParts.UPDATED_AT)
                                                }}
                                                className={styles.alignRight}
                                            >
                                                <div>
                                                    <Badge
                                                        text={formatDate(part.updatedAt)}
                                                        color="#ddd"
                                                        fontColor="#555"
                                                    />
                                                </div>
                                                {+countDaysInStock(part.updatedAt) > 10 ? (
                                                    <div>
                                                        <Badge
                                                            text={
                                                                countDaysInStock(part.updatedAt) +
                                                                ' ' +
                                                                num_word(+countDaysInStock(part.updatedAt), [
                                                                    'день',
                                                                    'дня',
                                                                    'дней',
                                                                ])
                                                            }
                                                            color="#eee"
                                                            fontColor="#777"
                                                        />
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        )
                                    case 'quantity':
                                        return (
                                            <td key={column.id} scope="row">
                                                <CellQuantityParts part={part} />
                                            </td>
                                        )
                                    case 'actions':
                                        return (
                                            <td key={column.id} scope="row" className={styles.default}>
                                                <CellActionsParts
                                                    part={part}
                                                    isSafari={browserName === 'Safari'}
                                                    savedSuccessfully={
                                                        isPartSavedSuccessfully?.partId === part.id
                                                            ? isPartSavedSuccessfully.success
                                                            : undefined
                                                    }
                                                />
                                            </td>
                                        )
                                    case 'position':
                                        return (
                                            <td key={column.id} scope="row" onClick={() => openEditModal(part)}>
                                                {part.position}
                                            </td>
                                        )
                                    case 'warehousePlace':
                                        return (
                                            <td key={column.id} scope="row" onClick={() => openEditModal(part)}>
                                                {part.extraData?.warehouse?.place}
                                            </td>
                                        )
                                    case 'sticker':
                                        return (
                                            <td key={column.id} scope="row" className={styles.default}>
                                                <span className={styles.pointer}>
                                                    <Printer size={fontSizes.s} onClick={() => printPart(part)} />
                                                </span>
                                            </td>
                                        )
                                    case 'partId':
                                        return (
                                            <td key={column.id} scope="row" onClick={() => openEditModal(part)}>
                                                {part.extraData?.avitoParams?.Id
                                                    ? part.extraData.avitoParams.Id
                                                    : part.id}
                                            </td>
                                        )
                                    case 'incomingInvoiceTitle':
                                        return (
                                            <td key={column.id} scope="row" onClick={() => openEditModal(part)}>
                                                {part.incomingInvoiceTitle}
                                            </td>
                                        )
                                    default:
                                        return (
                                            <td key={column.id} scope="row">
                                                Неизвестный столбец
                                            </td>
                                        )
                                }
                            })}
                        </tr>
                    ))}
                </tbody>
            )}
        </Table>
    )
}

export default TablePartList
