import React from 'react'

import { colors } from '../../../constants/salesStyles'
import getAddressSuggestions from '../../../modules/redux/sales/getAddressSuggestions'
import { useSelector } from '../../../modules/store/customSelector'
import { fieldTypes } from '../../../types/common/commonTypes'
import { contactInput } from '../../../types/sales/salesTypes'
import CustomLink from '../../_atoms/CustomLink/CustomLink'
import SalesSelectSearch from '../SalesSelectSearch/SalesSelectSearch'
import SalesTextEditable from '../SalesTextEditable/SalesTextEditable'

import styles from './styles.module.scss'

interface IProps {
    input: contactInput
    isHover: boolean
    isEditable: boolean
    onChangeValue: (value: string | number | boolean) => void
    onChangeRenderValue: (value: string) => void
}

const SalesTableDataContent = ({ input, isHover, isEditable, onChangeValue, onChangeRenderValue }: IProps) => {
    const { value, fieldType, specialProps } = input

    const addressSuggestions = useSelector(state => state.sales.addressSuggestions)
    const isLoading = useSelector(state => state.sales.loaders.addressSuggestions)
    const getTableData = () => {
        switch (true) {
            case fieldType === fieldTypes.url:
                return <CustomLink text={String(value)} href={String(value)} target="_blank" truncate={true} />
            case fieldType === fieldTypes.selectSearch:
                return (
                    <SalesSelectSearch
                        renderValue={specialProps?.renderValue || ''}
                        onChangeValue={onChangeValue}
                        onChangeRenderValue={onChangeRenderValue}
                        isEditable={isEditable}
                        suggestions={addressSuggestions}
                        getSuggestions={getAddressSuggestions}
                        maxOptions={6}
                        isLoading={isLoading}
                    />
                )
            default:
                return (
                    <div className={`${specialProps?.truncate ? styles.ellipsis : ''}`}>
                        <SalesTextEditable
                            onChangeValue={onChangeValue}
                            renderValue={String(value)}
                            color={isHover ? colors.purple : colors.black}
                            isEditable={isEditable}
                        />
                    </div>
                )
        }
    }

    return getTableData()
}

export default SalesTableDataContent
