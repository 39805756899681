import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { RangeInput } from '../../../types/common/commonTypes'
import { MarketplacesKeys } from '../../../types/part/partTypes'
import { apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

let prevController: AbortController | null = null

const getSummaryByUserId =
    (dateRange?: RangeInput, marketplace?: MarketplacesKeys, partsId?: string[]) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        // Отменить предыдущий запрос, если он существует
        if (prevController) {
            prevController.abort()
        }

        const controller = new AbortController()
        const { signal } = controller
        prevController = controller

        const { userData } = getState()
        const config = {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
            },
            signal,
        }
        try {
            const {
                data: { status, data },
            } = await apiInstance.post(
                '/sales/stats/getSummaryByUserId',
                {
                    userId: userData.smsAuth.userId,
                    dateRange,
                    marketplace,
                    partsId,
                },
                config,
            )
            if (status === 'ok') {
                dispatch({
                    type: storeDispatchTypes.setUserSummary,
                    value: {
                        userSummary: data,
                    },
                })
            }
        } catch (e) {
            console.error('get user summary failed', e)
        }
    }

export default getSummaryByUserId
