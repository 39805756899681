export const defaultCustomizableColumns = [
    { id: 'groupId', label: 'Иконка категории' },
    { id: 'customTitle', label: 'Название запчасти' },
    { id: 'markTitle', label: 'Марка авто' },
    { id: 'modelTitle', label: 'Модель авто' },
    { id: 'photo', label: 'Фото' },
    { id: 'price', label: 'Цена' },
    { id: 'accepted', label: 'Принято' },
    { id: 'vendorCode', label: 'Артикул' },
    { id: 'partNumber', label: 'OEM' },
    { id: 'createdAt', label: 'Дата создания' },
    { id: 'updatedAt', label: 'Дата ред' },
    { id: 'quantity', label: 'Количество' },
    { id: 'actions', label: 'Действия' },
]

export const allCustomizableColumns = [
    ...defaultCustomizableColumns,
    { id: 'position', label: 'Расположение' },
    { id: 'warehousePlace', label: 'Место на складе' },
    { id: 'sticker', label: 'QR' },
    { id: 'partId', label: 'Avito ID' },
    { id: 'incomingInvoiceTitle', label: 'Номер приходной накладной' },
    { id: 'pricePurchase', label: 'Цена закупки' },
]

export const getOrderColumns = (order: string[]) =>
    order.map(id => allCustomizableColumns.find(column => column.id === id)).filter(Boolean)
