import { AnyAction } from 'redux'

import {
    fieldUpdateObject,
    carFields,
    carCreateUpdateActionTypes,
    carInput,
    carState,
    checkbox,
} from '../../types/car/carTypes'
import { fieldTypes, valueTypes } from '../../types/common/commonTypes'

export const carReducer = (state: carState, action: AnyAction) => {
    const getFieldIndex = (fieldToSearch: carFields) =>
        state.fields.findIndex((field: carInput) => field.field === fieldToSearch)

    const convertValueType = (value: number | string | boolean, valueType: valueTypes) => {
        switch (true) {
            case valueType === valueTypes.string:
                return String(value)
            case valueType === valueTypes.boolean:
                return Boolean(value)
            case valueType === valueTypes.number:
                return Number(String(value).replaceAll(/[^0-9]/g, ''))
            case valueType === valueTypes.price:
                return Number(
                    String(value)
                        .substring(0, 10)
                        .replaceAll(/[^0-9]/g, ''),
                )
            case valueType === valueTypes.files:
                return value
            case valueType === valueTypes.array:
                return value
            default:
                console.error(`check value type ${value} is ${typeof value}`)
                return value
        }
    }

    // Проверка на тип массива
    function isArrayOfTypeFile(arr: (File | checkbox)[]): arr is File[] {
        return arr.every(item => item instanceof File)
    }

    const updateFieldValue = () => {
        const stateFields: carInput[] = [...state.fields]

        const fieldToUpdateIndex = getFieldIndex(action.value.field)
        if (fieldToUpdateIndex === -1) {
            return state
        }

        const convertedValue = convertValueType(action.value.value, stateFields[fieldToUpdateIndex].valueType)
        stateFields[fieldToUpdateIndex] = { ...stateFields[fieldToUpdateIndex], value: convertedValue }

        const specialPropsToUpdate = action.value.specialProps
        if (specialPropsToUpdate) {
            stateFields[fieldToUpdateIndex] = {
                ...stateFields[fieldToUpdateIndex],
                specialProps: { ...specialPropsToUpdate },
            }
        }

        return { ...state, fields: stateFields }
    }

    const updateMultipleFieldValues = () => {
        const stateFields: carInput[] = [...state.fields]

        action.value.map((item: fieldUpdateObject) => {
            const fieldToUpdateIndex = getFieldIndex(item.field)
            const newValue = item.value
            if (newValue || newValue === '' || newValue === 0) {
                const convertedValue = convertValueType(newValue, stateFields[fieldToUpdateIndex].valueType)
                stateFields[fieldToUpdateIndex] = { ...stateFields[fieldToUpdateIndex], value: convertedValue }
            }

            const specialPropsFieldToUpdate = item.specialPropsField
            if (specialPropsFieldToUpdate) {
                stateFields[fieldToUpdateIndex] = {
                    ...stateFields[fieldToUpdateIndex],
                    specialProps: {
                        ...stateFields[fieldToUpdateIndex]?.specialProps,
                        [specialPropsFieldToUpdate]: item.specialPropsValue,
                    },
                }
            }
        })

        return { ...state, fields: stateFields }
    }

    const updateFieldSpecialProps = () => {
        const fieldToUpdateIndex = getFieldIndex(action.value.field)
        if (fieldToUpdateIndex === -1) {
            return state
        }

        const stateFields: carInput[] = [...state.fields]

        stateFields[fieldToUpdateIndex] = {
            ...stateFields[fieldToUpdateIndex],
            specialProps: { ...action.value.specialProps },
        }

        return { ...state, fields: stateFields }
    }

    const uploadFiles = () => {
        const fieldToUpdateIndex = getFieldIndex(action.value.field)
        if (fieldToUpdateIndex === -1) {
            return state
        }

        const stateFields: carInput[] = [...state.fields]

        const currentFiles = stateFields[fieldToUpdateIndex]?.value || []
        if (currentFiles && Array.isArray(currentFiles)) {
            const newFiles = [...currentFiles, ...action.value.files]

            if (newFiles.length > 10) {
                newFiles.length = 10
            }

            stateFields[fieldToUpdateIndex] = {
                ...stateFields[fieldToUpdateIndex],
                value: newFiles,
            }
        }

        return { ...state, fields: stateFields }
    }

    const removeFile = () => {
        const fieldToUpdateIndex = getFieldIndex(action.value.field)
        if (fieldToUpdateIndex === -1) {
            return state
        }

        const stateFields: carInput[] = [...state.fields]

        const currentFiles = stateFields[fieldToUpdateIndex]?.value || []
        if (currentFiles && Array.isArray(currentFiles) && isArrayOfTypeFile(currentFiles)) {
            currentFiles.splice(action.value.fileIndex, 1)

            stateFields[fieldToUpdateIndex] = {
                ...stateFields[fieldToUpdateIndex],
                value: [...currentFiles],
            }
        }

        return { ...state, fields: stateFields }
    }

    const reorderFiles = () => {
        const fieldToUpdateIndex = getFieldIndex(action.value.field)
        if (fieldToUpdateIndex === -1) {
            return state
        }

        const stateFields: carInput[] = [...state.fields]

        const currentFiles = stateFields[fieldToUpdateIndex]?.value || []
        if (currentFiles && Array.isArray(currentFiles)) {
            const newFiles = [...action.value.files]

            if (newFiles.length > 10) {
                newFiles.length = 10
            }

            stateFields[fieldToUpdateIndex] = {
                ...stateFields[fieldToUpdateIndex],
                value: newFiles,
            }
        }

        return { ...state, fields: stateFields }
    }

    const updateChecboxGroupColumn = () => {
        const stateFields: carInput[] = [...state.fields]

        const fieldToUpdateIndex = getFieldIndex(action.value.field)
        if (fieldToUpdateIndex === -1) {
            return state
        }

        const currentCheckboxes = stateFields[fieldToUpdateIndex]

        if (
            currentCheckboxes.value &&
            Array.isArray(currentCheckboxes.value) &&
            !isArrayOfTypeFile(currentCheckboxes.value)
        ) {
            const updatedValues = currentCheckboxes.value || []
            const updatedCheckboxItems = currentCheckboxes.specialProps?.checkboxItems || []

            updatedValues.forEach(defect => {
                if (action.value?.value?.includes(defect.field)) {
                    defect.value = true
                }
            })

            updatedCheckboxItems.forEach(defect => {
                if (action.value?.value?.includes(defect.field)) {
                    defect.value = true
                }
            })

            stateFields[fieldToUpdateIndex] = {
                ...stateFields[fieldToUpdateIndex],
                value: [...currentCheckboxes.value],
                specialProps: { ...currentCheckboxes.specialProps },
            }
        }

        return { ...state, fields: stateFields }
    }

    const updateFieldTypeToVoid = () => {
        const stateFields: carInput[] = [...state.fields]

        action.value.forEach((field: carFields) => {
            const fieldToUpdateIndex = getFieldIndex(field)
            if (fieldToUpdateIndex !== -1) {
                stateFields[fieldToUpdateIndex] = {
                    ...stateFields[fieldToUpdateIndex],
                    fieldType: fieldTypes.void,
                }
            }
        })

        return { ...state, fields: stateFields }
    }

    switch (action.type) {
        case carCreateUpdateActionTypes.updateField: {
            return updateFieldValue()
        }
        case carCreateUpdateActionTypes.updateManyFields: {
            return updateMultipleFieldValues()
        }
        case carCreateUpdateActionTypes.initializeState: {
            return action.value.state
        }
        case carCreateUpdateActionTypes.updateSpecialProps:
            return updateFieldSpecialProps()
        case carCreateUpdateActionTypes.addFiles:
            return uploadFiles()
        case carCreateUpdateActionTypes.removeFile:
            return removeFile()
        case carCreateUpdateActionTypes.reorderFiles:
            return reorderFiles()
        case carCreateUpdateActionTypes.updateChecboxGroup:
            return updateChecboxGroupColumn()
        case carCreateUpdateActionTypes.updateFieldTypeToVoid:
            return updateFieldTypeToVoid()
        default:
            return state
    }
}
