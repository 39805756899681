import { Dispatch } from 'redux'

import { SelectOptionLocal } from '../../../types/common/commonTypes'
import { apiInstance } from '../../api/apiInstance'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getAllAudioBrands =
    (searchTerm: string) =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'audioBrands', value: true } })

        try {
            const {
                data: { status, data },
            } = await apiInstance.post('/dicts/avito/AudioBrands')
            const audioBrands: SelectOptionLocal[] = []

            if (status === 'ok') {
                const parser = new DOMParser()
                const xmlDoc = parser.parseFromString(data, 'text/xml')
                const brandsNodes = xmlDoc.getElementsByTagName('Brand')

                for (let i = 0; i < brandsNodes.length; i++) {
                    const brandName = brandsNodes[i].textContent?.trim()
                    if (brandName && brandName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                        audioBrands.push({ name: brandName, value: brandName })
                    }
                }
            }
            dispatch({ type: storeDispatchTypes.setAllAudioBrands, value: { audioBrands } })
        } catch (e) {
            console.error('load audioBrands failed', e)
        }

        dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'audioBrands', value: false } })
    }

export default getAllAudioBrands
