import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import removeFromListDelay from '../../../constants/removeFromListDelay'
import { Part } from '../../../types/part/partTypes'
import { apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const setPartSaleInfo =
    (part: Part, price: number, marketplace?: string, newSoldStatus = true) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { userData } = getState()
        const { id: partId, extraData: { qty = 1, soldQtyArr = [] } = {} } = part
        try {
            if (qty > 1) {
                const dateNow = Date.now()
                const {
                    data: { status },
                } = await apiInstance.post(
                    '/updatePartSoldQtyArray',
                    {
                        userId: userData.smsAuth.userId,
                        partId,
                        extraDataValue: {
                            ...part.extraData,
                            qty: qty - 1,
                            soldQtyArr: [
                                ...(soldQtyArr || []),
                                {
                                    price,
                                    marketplace,
                                    soldAt: dateNow,
                                },
                            ],
                        },
                        soldAt: dateNow,
                    },
                    {
                        headers: {
                            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
                        },
                    },
                )
                if (status === 'ok') {
                    dispatch({
                        type: storeDispatchTypes.updatePartExtraDataQuantity,
                        value: {
                            id: partId,
                            qty: qty - 1,
                        },
                    })

                    dispatch({
                        type: storeDispatchTypes.updatePartExtraDataSoldQuantityArr,
                        value: {
                            id: partId,
                            soldQtyArr: {
                                price,
                                marketplace,
                                soldAt: Date.now(),
                            },
                        },
                    })

                    dispatch({
                        type: storeDispatchTypes.setPartSoldStatus,
                        value: {
                            id: partId,
                            isSold: newSoldStatus,
                        },
                    })

                    setTimeout(() => {
                        dispatch({
                            type: storeDispatchTypes.setPartSoldStatus,
                            value: {
                                id: partId,
                                isSold: !newSoldStatus,
                                updatePendingOnly: true,
                            },
                        })
                    }, removeFromListDelay)
                }
            } else {
                const {
                    data: { status },
                } = await apiInstance.post(
                    '/setPartSaleInfo',
                    {
                        userId: userData.smsAuth.userId,
                        partId,
                        price,
                        salePlatform: marketplace,
                    },
                    {
                        headers: {
                            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
                        },
                    },
                )
                if (status === 'ok') {
                    dispatch({
                        type: storeDispatchTypes.setPartSoldStatus,
                        value: {
                            id: partId,
                            isSold: newSoldStatus,
                        },
                    })

                    setTimeout(() => {
                        dispatch({
                            type: storeDispatchTypes.removePartFormListByPartId,
                            value: {
                                id: partId,
                            },
                        })
                    }, removeFromListDelay)
                }
            }
        } catch (e) {
            console.error('set part sold status failed', e)
        }
    }

export default setPartSaleInfo
