import React, { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import getMarketplaceDescAddVendorCode from '../../../modules/redux/sales/getMarketplaceDescAddVendorCode'
import getMarketplaceDesctemplate from '../../../modules/redux/sales/getMarketplaceDesctemplate'
import getMarketplacesInfo from '../../../modules/redux/sales/getMarketplacesInfo'
import updateMarketplaceDescAddVendorCode from '../../../modules/redux/sales/updateMarketplaceDescAddVendorCode'
import updateMarketplaceDesctemplate from '../../../modules/redux/sales/updateMarketplaceDesctemplate'
import { AppDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import Loader from '../../_atoms/Loader/Loader'
import SalesCard from '../SalesCard/SalesCard'
import SalesCardTableMarketplaces from '../SalesCardTableMarketplaces/SalesCardTableMarketplaces'
import SalesDescriptionInput from '../SalesDescriptionInput'
import SingleAccordion from '../SingleAccordion/SingleAccordion'

import styles from './styles.module.scss'

interface IProps {
    footerContent: ReactElement
    limit?: number
    openModal: (marketplace: string) => void
}

const SalesCardMarketPlaces = ({ footerContent, limit, openModal }: IProps) => {
    const isLoading = useSelector(state => state.sales.loaders.marketplacesInfo)
    const dispatch = useDispatch<AppDispatch>()
    const marketplaces = useSelector(state => state.sales.marketplaces.info)
    const mpDescTemplate = useSelector(state => state.sales.marketplaces.mpDescTemplate)
    const [mpDescription, setMpDescription] = useState<string>('')
    const mpDescAddVendorCode = useSelector(state => state.sales.marketplaces.mpDescAddVendorCode)
    const [addVendorCode, setAddVendorCode] = useState(false)
    const [isLoadingOverlay, setIsLoadingOverlay] = useState(false)

    useEffect(() => {
        dispatch(getMarketplacesInfo())
        dispatch(getMarketplaceDesctemplate())
        dispatch(getMarketplaceDescAddVendorCode())
    }, [])

    const handleSalesDescriptionSubmit = async (): Promise<void> => {
        setIsLoadingOverlay(true)
        await dispatch(updateMarketplaceDesctemplate(mpDescription))
        await dispatch(updateMarketplaceDescAddVendorCode(addVendorCode))
        setIsLoadingOverlay(false)
    }

    useEffect(() => {
        setMpDescription(mpDescTemplate)
    }, [mpDescTemplate])

    useEffect(() => {
        setAddVendorCode(mpDescAddVendorCode)
    }, [mpDescAddVendorCode])

    return (
        <div>
            <SalesCard
                widthInPixels={570}
                heightInPixels={isLoading ? 290 : undefined}
                outerTitle={'Мои маркетплейсы'}
                footer={true}
                footerContent={footerContent}
                overflowY={'visible'}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <SalesCardTableMarketplaces marketplaces={marketplaces} limit={limit} openModal={openModal} />
                )}
            </SalesCard>
            <SingleAccordion marginTopPixels={12} width="570px" title="Дополнительное описание для всех обьявлений">
                <>
                    {isLoadingOverlay ? (
                        <Loader />
                    ) : (
                        <SalesDescriptionInput
                            value={mpDescription}
                            onChangeTextArea={setMpDescription}
                            onSubmit={() => handleSalesDescriptionSubmit()}
                            errorMessage={'Превышен лимит'}
                            hideButton={mpDescription == mpDescTemplate && mpDescAddVendorCode == addVendorCode}
                        >
                            <Form.Check className={styles.formCheck}>
                                <Form.Check.Input
                                    checked={addVendorCode}
                                    onChange={() => setAddVendorCode(!addVendorCode)}
                                />
                                <Form.Check.Label>Добавлять артикул к описаниям</Form.Check.Label>
                            </Form.Check>
                        </SalesDescriptionInput>
                    )}
                </>
            </SingleAccordion>
        </div>
    )
}

export default SalesCardMarketPlaces
