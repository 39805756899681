import { useNavigate } from 'react-router'

import { EDocumentType } from '../../../types/document/documentTypes'

import styles from './styles.module.scss'

interface IProps {
    slice?: EDocumentType
}

const ListDocumentEmpty = ({ slice }: IProps) => {
    const navigate = useNavigate()
    let message = 'message'
    switch (slice) {
        case EDocumentType.Invoice:
            message = 'первую расходную накладную'
            break
        case EDocumentType.Receipt:
            message = 'первый чек'
            break
        case EDocumentType.IncomingInvoice:
            message = 'первую приходную накладную'
            break
    }

    const goToPartList = () => {
        navigate(`/admin/parts`)
    }

    return (
        <div className={styles.wrap}>
            <p>
                Выделите позиции в{' '}
                <span className={styles.reset} onClick={goToPartList}>
                    списке запчастей
                </span>{' '}
                галочками и сформируйте {message}
            </p>
        </div>
    )
}

export default ListDocumentEmpty
