import { useState } from 'react'
import { Button, Form, Modal, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import createDocument from '../../../modules/redux/documentList/CreateDocument'
import setPartSaleInfo from '../../../modules/redux/partList/setPartSaleInfo'
import { AppDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { EDocumentType } from '../../../types/document/documentTypes'
import { Part } from '../../../types/part/partTypes'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'

import styles from './styles.module.scss'

interface IProps {
    isOpen: boolean
    closeModal: () => void
    checks: {
        documentType: EDocumentType[]
        marketplace?: string
    }
    parts: Part[]
}

const ModalDataDocument = ({ isOpen, closeModal, checks, parts }: IProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const isLoading = useSelector(state => state.documentList.loaders.isDocumentCreated)

    const [number, setNumber] = useState('')
    const [buyerName, setBuyerName] = useState('')
    const [buyerAddress, setBuyerAddress] = useState('')
    const [buyerTelephone, setBuyerTelephone] = useState('')
    const [comment, setComment] = useState('')

    const onCloseModal = () => {
        closeModal()
    }

    const confirmChange = async () => {
        const document = {
            title: number,
            type: checks.documentType,
            extra_data: {
                number,
                buyerName,
                buyerAddress,
                buyerTelephone,
                comment,
                parts,
                amount: parts.reduce((sum, part) => sum + part.price, 0),
            },
        }

        try {
            await Promise.all(parts.map(part => dispatch(setPartSaleInfo(part, part.price, checks.marketplace))))
            await dispatch(createDocument(document))
            closeModal()
        } catch (error) {
            console.error('Ошибка при выполнении запросов:', error)
        }
    }

    return (
        <>
            <Modal className={styles.modalDataDocument} show={isOpen} onHide={onCloseModal} centered>
                {isLoading && (
                    <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                        <Loader diameterInPx={100} thicknessInPx={10} />
                    </OverlayWithText>
                )}
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Укажите данные для документов</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formNumber">
                            <Form.Label column sm="5">
                                Номер
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setNumber(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formName">
                            <Form.Label column sm="5">
                                Название покупателя
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setBuyerName(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formAddress">
                            <Form.Label column sm="5">
                                Адрес покупателя
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setBuyerAddress(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formTelephone">
                            <Form.Label column sm="5">
                                Телефон покупателя
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control onChange={e => setBuyerTelephone(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formComment">
                            <Form.Label column sm="5">
                                Комментарий
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control as="textarea" onChange={e => setComment(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button onClick={confirmChange}>Сохранить</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalDataDocument
