export const enum storeDispatchTypes {
    setCarsLoading = 'setCarsLoading',
    setCarList = 'setCarList',
    setPartList = 'setPartList',
    setWarehouseList = 'setWarehouseList',
    setMarketplacesList = 'setMarketplacesList',
    setMpDescTemplate = 'setMpDescTemplate',
    setMpDescAddVendorCode = 'setMpDescAddVendorCode',
    setWarehousesLoading = 'setWarehousesLoading',
    setPartListLoading = 'setPartListLoading',
    setSellPartModal = 'setSellPartModal',
    setUserAuthData = 'setUserAuthData',
    setPartType = 'setPartType',
    setUserPerms = 'setUserPerms',

    getPartDescTemplates = 'getPartDescTemplates',

    // Update part details
    updatePartPrice = 'updatePartPrice',
    updatePartCarData = 'updatePartCarData',
    updatePartDescription = 'updatePartDescription',
    updatePartCondition = 'updatePartCondition',
    updatePartNumber = 'updatePartNumber',
    updateVendorCode = 'updateVendorCode',
    updatePartImages = 'updatePartImages',
    setPartPriceLoading = 'setPartPriceLoading',
    setPartCarDataLoading = 'setPartCarDataLoading',
    deletePartImage = 'deletePartImage',
    reorderPartImages = 'reorderPartImages',
    updatePartCustomTitle = 'updatePartCustomTitle',
    updatePartExtraDataValue = 'updatePartExtraDataValue',
    updatePartExtraDataFieldValue = 'updatePartExtraDataFieldValue',
    updatePartTypeId = 'updatePartTypeId',
    updatePartTitle = 'updatePartTitle',
    updatePartGroupId = 'updatePartGroupId',
    updatePartExtraDataQuantity = 'updatePartExtraDataQuantity',
    updatePartExtraDataSoldQuantityArr = 'updatePartExtraDataSoldQuantityArr',

    // Sold and Deleted
    setPartSoldStatus = 'setPartSoldStatus',
    setPartDeletedStatus = 'setPartDeletedStatus',
    setPartArchivedStatus = 'setPartArchivedStatus',
    setCarDeletedStatus = 'setCarDeletedStatus',
    removeDeletedPartFormList = 'removeDeletedPartFormList',
    removeArchivedPartFormList = 'removeArchivedPartFormList',
    removePartFormListByPartId = 'removePartFormListByPartId',
    removeDeletedCarFormList = 'removeDeletedCarFormList',

    // Parts sort
    setPartListSort = 'setPartListSort',

    // Parts filters
    setModelFilterParts = 'setModelFilterParts',
    setPartNumberFilterParts = 'setPartNumberFilterParts',
    setFilterDateFilterParts = 'setFilterDateFilterParts',
    setFilterDateFieldFilterParts = 'setFilterDateFieldFilterParts',
    setFilterWarehouseIdParts = 'setFilterWarehouseIdParts',
    setCategoryFilterParts = 'setCategoryFilterParts',
    setMarkFilterParts = 'setMarkFilterParts',
    setPartTitleFilterParts = 'setPartTitleFilterParts',
    setCarFilterParts = 'setCarFilterParts',
    setPartIdsFilterParts = 'setPartIdsFilterParts',
    setPageFilterParts = 'setPageFilterParts',
    setWithPriceParts = 'setWithPriceParts',
    setWithPhotoParts = 'setWithPhotoParts',
    setWithDescriptionParts = 'setWithDescriptionParts',
    setWithPartNumberParts = 'setWithPartNumberParts',
    setWithMarkModelModificationAndTypeIdParts = 'setWithMarkModelModificationAndTypeIdParts',
    setWithCarId = 'setWithCarId',
    setWithAvitoFullInfo = 'setWithAvitoFullInfo',
    setPartListParamsMarketplace = 'setPartListParamsMarketplace',
    // for text search filter, no select-search
    setPartNameTextFilterParts = 'setPartNameTextFilterParts',
    setDocumentFilterParts = 'setDocumentFilterParts',

    // Parts reset filters
    resetFiltersParts = 'resetFiltersParts',
    resetCarFilterParts = 'resetCarFilterParts',
    // Cars reset filters
    resetFiltersCars = 'resetFiltersCars',

    // Parts select-search collections
    setModelsParts = 'setModelsParts',
    setPartNumbersParts = 'setPartNumbersParts',
    setPartNumbersPartsWithDescription = 'setPartNumbersPartsWithDescription',
    setCategoriesParts = 'setCategoriesParts',
    setMarksParts = 'setMarksParts',
    setPriceRangeParts = 'setPriceRangeParts',
    setYearRangeParts = 'setYearRangeParts',
    setPartTitlesParts = 'setPartTitlesParts',
    setAllPartTypesParts = 'setAllPartTypesParts',
    setWarehousesSelectSearch = 'setWarehousesSelectSearch',
    setDateRangeParts = 'setDateRangeParts',

    // Parts select
    setSelectAllParts = 'setSelectAllParts',
    setSelectedParts = 'setSelectedParts',
    setCurrentPart = 'setCurrentPart',

    // Cars select-search collections
    setPartsLoader = 'setPartsLoader',

    // Cars filters
    setMarkFilterCars = 'setMarkFilterCars',
    setModelFilterCars = 'setModelFilterCars',
    setModificationFilterCars = 'setModificationFilterCars',
    setBodyTypeFilterCars = 'setBodyTypeFilterCars',
    setPartsCountRangeCars = 'setPartsCountRangeCars',
    setPriceRangeCars = 'setPriceRangeCars',
    setMileageRange = 'setMileageRange',
    setPageFilterCars = 'setPageFilterCars',

    // Cars select-search collections
    seCarsLoader = 'seCarsLoader',

    setCars = 'setCars',
    setMarksCars = 'setMarksCars',
    setAllMarksCars = 'setAllMarksCars',
    setModelsCars = 'setModelsCars',
    setAllModelsCars = 'setAllModelsCars',
    setModificationsCars = 'setModificationsCars',
    setAllModificationsCars = 'setAllModificationsCars',
    setFilteredModificationsCars = 'setFilteredModificationsCars',
    setModificationYears = 'setModificationYears',
    setBodyTypesCars = 'setBodyTypesCars',
    setBodyTypeCreateCar = 'setBodyTypeCreateCar',

    // Update car
    deleteCarImage = 'deleteCarImage',
    reorderCarImages = 'reorderCarImages',

    // warehouse
    deleteWarehouseById = 'deleteWarehouseById',

    // avito Dicts
    setAllTiresBrands = 'setAllTiresBrands',
    setAllPartBrands = 'setAllPartBrands',
    setAllTiresModels = 'setAllTiresModels',
    setAllPartGroups = 'setAllPartGroups',
    setAllOilsBrands = 'setAllOilsBrands',
    setAllOilsVolumes = 'setAllOilsVolumes',
    setAllOilsSAE = 'setAllOilsSAE',
    setAllOilsACEA = 'setAllOilsACEA',
    setAllOilsAPI = 'setAllOilsAPI',
    setAllOilsOEMOil = 'setAllOilsOEMOil',
    setAllOilsATF = 'setAllOilsATF',
    setAllOilsASTM = 'setAllOilsASTM',
    setAllCoolantColors = 'setAllCoolantColors',
    setAllBrakeDOT = 'setAllBrakeDOT',
    setAllRadioBrands = 'setAllRadioBrands',
    setAllRadioSizes = 'setAllRadioSizes',
    setAllAmplifierBrands = 'setAllAmplifierBrands',
    setAllAudioBrands = 'setAllAudioBrands',
    setAllAudioSizes = 'setAllAudioSizes',

    // Cars sort
    setCarListSort = 'setCarListSort',

    // SMS Auth
    setCountDownResendSms = 'setCountDownResendSms',
    setAuthErrorSms = 'setAuthError',
    setCodeSentSms = 'setCodeSentSms',
    setIsLoadingSms = 'setIsLoadingSms',
    setUserIdSms = 'setUserIdSms',
    setUserPhoneNumberSms = 'setUserPhoneNumberSms',
    setIsRefreshingSms = 'setIsRefreshingSms',
    setIsActive = 'setIsActive',
    setAccountPaymentInfo = 'setAccountPaymentInfo',
    setAccountCashierInfo = 'setAccountCashierInfo',
    setAccountTaskInfo = 'setAccountTaskInfo',

    // sales
    setSalesLoader = 'setSalesLoader',
    setSalesAddressSuggestions = 'setSalesAddressSuggestions',
    // sales (marketplaces)
    setMarketplacesContact = 'setMarketplacesContact',
    setMarketplacesInfo = 'setMarketplacesInfo',
    setMarketplacesSettings = 'setMarketplacesSettings',
    // sales (highlights)
    setHighlights = 'setHighlights',
    setUserSummary = 'setUserSummary',
    // sales Shop
    setShopInfo = 'setShopInfo',
    setShopCategories = 'setShop',

    // documents
    setDocumentListLoading = 'setDocumentListLoading',
    setDocumentList = 'setDocumentList',
    setDocumentsLoader = 'setDocumentsLoader',
    setDocumentDeletedStatus = 'setDocumentDeletedStatus',
    removeDeletedDocumentFormList = 'removeDeletedDocumentFormList',
    setAllDocuments = 'setAllDocuments',
    setDocument = 'setDocument',
    updatePartsWithIncomingInvoicesTitle = 'updatePartsWithIncomingInvoicesTitle',
    // documents filters
    resetFiltersDocuments = 'resetFiltersDocuments',
    setPageFilterDocuments = 'setPageFilterDocuments',

    // applicability
    setApplicabilitySelected = 'setApplicabilitySelected',
    setApplicabilitySuggestion = 'setApplicabilitySuggestion',

    // customizableColumns
    setCustomizableColumns = 'setCustomizableColumns',
    setCustomizableColumnsLoading = 'setCustomizableColumnsLoading',

    // currentCurrency
    setCurrentCurrency = 'setCurrentCurrency',

    // Сохранилась ли запчасть успешно или с ошибкой
    setIsPartSavedSuccessfully = 'setIsPartSavedSuccessfully',
    removeIsPartSavedSuccessfully = 'removeIsPartSavedSuccessfully',

    // actionLog
    setActionLogList = 'setActionLogList',
    setActionLogListLoading = 'setActionLogListLoading',
    resetFiltersActionLogList = 'resetFiltersActionLogList',
    setPageFilterActionLogList = 'setPageFilterActionLogList',
    setPartNameTextFilterActionLogList = 'setPartNameTextFilterActionLogList',
    setMarkFilterActionLogList = 'setMarkFilterActionLogList',
    setMarksActionLogList = 'setMarksActionLogList',
    setModelFilterActionLogList = 'setModelFilterActionLogList',
    setFilterDateFilterActionLogList = 'setFilterDateFilterActionLogList',
    setActionLogListSort = 'setActionLogListSort',
    setPartNumbersActionLogList = 'setPartNumbersActionLogList',
    setPartNumberFilterActionLogList = 'setPartNumberFilterActionLogList',
    setPriceRangeActionLogList = 'setPriceRangeActionLogList',
}
