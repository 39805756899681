import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { PlusLg } from 'react-bootstrap-icons'
import { browserName } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useLocation, NavigateFunction, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import Badge from '../../components/_atoms/Badge/Badge'
import CellSoldPercent from '../../components/_atoms/CellSoldPercent/CellSoldPercent'
import ListNoItems from '../../components/_atoms/ListNoItems/ListNoItems'
import ListPagination from '../../components/_atoms/ListPagination/ListPagination'
import ListSkeleton from '../../components/_atoms/ListSkeleton/ListSkeleton'
import SalesButton from '../../components/_atoms/SalesButton/SalesButton'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import ColumnSort from '../../components/_molecules/ColumnSort/ColumnSort'
import ModalEditCar from '../../components/_molecules/ModalEditCar/ModalEditCar'
import ModalDeleteCar from '../../components/_molecules/ModelDeleteCar/ModelDeteteCar'
import TableHeadBodyTypeSearchCars from '../../components/_molecules/TableHeadBodyTypeSearchCars/TableHeadBodyTypeSearchCars'
import TableHeadMarkSearchCars from '../../components/_molecules/TableHeadMarkSearchCars/TableHeadMarkSearchCars'
import TableHeadMileageRangeCars from '../../components/_molecules/TableHeadMileageRangeCars/TableHeadMileageRangeCars'
import TableHeadModelSearchCars from '../../components/_molecules/TableHeadModelSearchCars/TableHeadModelSearchCars'
import TableHeadModificationSearchCars from '../../components/_molecules/TableHeadModificationSearchCars/TableHeadModificationSearchCars'
import TableHeadPartsCountRangeCars from '../../components/_molecules/TableHeadPartsCountRangeCars/TableHeadPartsCountRangeCars'
import TableHeadPriceRangeCars from '../../components/_molecules/TableHeadPriceRangeCars/TableHeadPriceRangeCars'
import CellActionsCars from '../../components/_orgamisms/CellActionsCars/CellActionsCars'
import CellThumbCarousel from '../../components/_orgamisms/CellThumbCarousel/CellThumbCarousel'
import { colors, fontSizes, fontWeights } from '../../constants/salesStyles'
import DatahubApiService from '../../modules/api/DatahubApiService'
import LoadCarList from '../../modules/redux/carList/LoadCarList'
import { useSelector } from '../../modules/store/customSelector'
import { storeDispatchTypes } from '../../modules/store/storeDispatchTypes'
import { adminRouteAlias, getAdminNavigationPath } from '../../router/adminRouteAlias'
import { Car, CarListPageParams } from '../../types/car/carTypes'
import { SortFieldCars, SortIconType } from '../../types/common/commonTypes'
import { countDaysInStock, formatDate, num_word } from '../../utils/converters'
import { colWidth } from '../../utils/stylePatches'

import styles from './styles.module.scss'

const CarList = () => {
    const params = useParams<CarListPageParams>()
    const dispatch = useDispatch()
    const [perPage] = useState(50)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [currentCar, setCurrentCar] = useState<Car | null>(null)

    const itemsCount = useSelector(state => state.carList.carList.dataCount || 0)
    const isLoading = useSelector(state => state.carList.isLoading)
    const cars = useSelector(state => state.carList.carList.data)
    const canShowFinanceInfo = useSelector(state => state.userData.perms?.canShowFinanceInfo)

    //Sort
    const sortOrder = useSelector(state => state.carList.sortOrder)

    // Select Search Filters
    const markId = useSelector(state => state.carList.filters.markId)
    const modelId = useSelector(state => state.carList.filters.modelId)
    const modificationId = useSelector(state => state.carList.filters.modificationId)
    const bodyTypeId = useSelector(state => state.carList.filters.bodyTypeId)

    //Range filters
    const partsCntRange = useSelector(state => state.carList.filters.partsCntRange)
    const partsCntFrom = useSelector(state => state.carList.filters.partsCntRange?.from)
    const partsCntTo = useSelector(state => state.carList.filters.partsCntRange?.to)

    const priceRange = useSelector(state => state.carList.filters.priceRange)
    const priceFrom = useSelector(state => state.carList.filters.priceRange?.from)
    const priceTo = useSelector(state => state.carList.filters.priceRange?.to)

    const mileageRange = useSelector(state => state.carList.filters.mileageRange)
    const mileageFrom = useSelector(state => state.carList.filters.mileageRange?.from)
    const mileageTo = useSelector(state => state.carList.filters.mileageRange?.to)
    const page = useSelector(state => state.carList.filters.page)

    const location = useLocation()
    const navigate: NavigateFunction = useNavigate()
    const goToCarDetails = (e: React.MouseEvent<HTMLTableCellElement>, car: Car) => {
        navigate(getAdminNavigationPath(adminRouteAlias.parts.location) + `?carId=${car.id}`)
        dispatch({
            type: storeDispatchTypes.setCarFilterParts,
            value: {
                car: {
                    carId: [car.id],
                    markTitle: car.markTitle,
                    modelTitle: car.modelTitle,
                    year: car.year,
                },
            },
        })
    }

    // Параметры поиска
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchParamsLoaded, setSearchParamsLoaded] = useState(0)
    const searchParamPage = searchParams.get('p')
    const searchParamMarkId = searchParams.get('markId')
    const searchParamModelId = searchParams.get('modelId')
    const searchParamModificationId = searchParams.get('modificationId')
    const searchParamBodyTypeId = searchParams.get('bodyTypeId')
    const searchParamMileageFrom = searchParams.get('mileageFrom')
    const searchParamMileageTo = searchParams.get('mileageTo')
    const searchParamPriceFrom = searchParams.get('priceFrom')
    const searchParamPriceTo = searchParams.get('priceTo')
    const searchParamPartsCntFrom = searchParams.get('partsCntFrom')
    const searchParamPartsCntTo = searchParams.get('partsCntTo')
    const searchParamSortOrder = searchParams.get('sortOrder')

    const navigateWithParams = (carId = '') => {
        const queryParams = new URLSearchParams(location.search)

        if (carId) {
            if (params.carId !== carId) {
                navigate(`/admin/cars/${carId}?${queryParams.toString()}`)
            }
        } else {
            navigate(`/admin/cars?${queryParams.toString()}`)
        }
    }

    const filterPartsByMarkId = (markId: number | string) => {
        dispatch({
            type: storeDispatchTypes.setMarkFilterCars,
            value: {
                filterId: markId,
            },
        })
    }

    const filterPartsByModelId = (modelId: number | string) => {
        dispatch({
            type: storeDispatchTypes.setModelFilterCars,
            value: {
                filterId: modelId,
            },
        })
    }

    const filterPartsByModificationId = (modificationId: number | string) => {
        dispatch({
            type: storeDispatchTypes.setModificationFilterCars,
            value: {
                filterId: modificationId,
            },
        })
    }

    const filterPartsByBodyTypeId = (bodyTypeId: number | string) => {
        dispatch({
            type: storeDispatchTypes.setBodyTypeFilterCars,
            value: {
                filterId: bodyTypeId,
            },
        })
    }

    const filterPartsByMileageRange = (mileageFrom: string | null, mileageTo: string | null) => {
        dispatch({
            type: storeDispatchTypes.setMileageRange,
            value: {
                from: mileageFrom,
                to: mileageTo,
            },
        })
    }

    const filterPartsByPriceRange = (priceFrom: string | null, priceTo: string | null) => {
        dispatch({
            type: storeDispatchTypes.setPriceRangeCars,
            value: {
                from: priceFrom,
                to: priceTo,
            },
        })
    }

    const filterPartsByPartsCntRange = (partsCntFrom: string | null, partsCntTo: string | null) => {
        dispatch({
            type: storeDispatchTypes.setPartsCountRangeCars,
            value: {
                from: partsCntFrom,
                to: partsCntTo,
            },
        })
    }

    const filterPartsBySortOrder = (sortField: string, sortOrder: string) => {
        dispatch({
            type: storeDispatchTypes.setCarListSort,
            value: {
                sortField,
                sortOrder,
            },
        })
    }

    const goToCarSoldPartList = (e: React.MouseEvent<HTMLTableCellElement>, car: Car) => {
        navigate(getAdminNavigationPath(adminRouteAlias.soldParts.location) + `?carId=${car.id}`)
        dispatch({
            type: storeDispatchTypes.setCarFilterParts,
            value: {
                car: {
                    carId: [car.id],
                    markTitle: car.markTitle,
                    modelTitle: car.modelTitle,
                    year: car.year,
                },
            },
        })
    }

    // Загрузка параметров из строки поиска
    useEffect(() => {
        if (searchParamPage !== `${page}` && (searchParamPage !== null || page !== 1)) {
            dispatch({
                type: storeDispatchTypes.setPageFilterParts,
                value: { page: Number(searchParamPage) > 1 ? Number(searchParamPage) : 1 },
            })
        }

        if (searchParamMarkId !== `${markId}` && (searchParamMarkId || markId !== undefined)) {
            filterPartsByMarkId(searchParamMarkId ? Number(searchParamMarkId) : '')
        }

        if (searchParamModelId !== `${modelId}` && (searchParamModelId || modelId !== undefined)) {
            filterPartsByModelId(searchParamModelId ? Number(searchParamModelId) : '')
        }

        if (
            searchParamModificationId !== `${modificationId}` &&
            (searchParamModificationId || modificationId !== undefined)
        ) {
            filterPartsByModificationId(searchParamModificationId ? Number(searchParamModificationId) : '')
        }

        if (searchParamBodyTypeId !== `${bodyTypeId}` && (searchParamBodyTypeId || bodyTypeId !== undefined)) {
            filterPartsByBodyTypeId(searchParamBodyTypeId ? Number(searchParamBodyTypeId) : '')
        }

        if (
            searchParamMileageFrom !== `${mileageFrom}` ||
            (searchParamMileageFrom === null && mileageFrom !== undefined) ||
            searchParamMileageTo !== `${mileageTo}` ||
            (searchParamMileageTo === null && mileageTo !== undefined)
        ) {
            if (
                searchParamMileageFrom ||
                mileageFrom !== undefined ||
                searchParamMileageTo ||
                mileageTo !== undefined
            ) {
                filterPartsByMileageRange(searchParamMileageFrom, searchParamMileageTo)
            }
        }

        if (
            searchParamPriceFrom !== `${priceFrom}` ||
            (searchParamPriceFrom === null && priceFrom !== undefined) ||
            searchParamPriceTo !== `${priceTo}` ||
            (searchParamPriceTo === null && priceTo !== undefined)
        ) {
            if (searchParamPriceFrom || priceFrom !== undefined || searchParamPriceTo || priceTo !== undefined) {
                filterPartsByPriceRange(searchParamPriceFrom, searchParamPriceTo)
            }
        }

        if (
            searchParamPartsCntFrom !== `${partsCntFrom}` ||
            (searchParamPartsCntFrom === null && partsCntFrom !== undefined) ||
            searchParamPartsCntTo !== `${partsCntTo}` ||
            (searchParamPartsCntTo === null && partsCntTo !== undefined)
        ) {
            if (
                searchParamPartsCntFrom ||
                partsCntFrom !== undefined ||
                searchParamPartsCntTo ||
                partsCntTo !== undefined
            ) {
                filterPartsByPartsCntRange(searchParamPartsCntFrom, searchParamPartsCntTo)
            }
        }

        if (searchParamSortOrder && searchParamSortOrder !== sortOrder.map(pair => pair.join(',')).join(',')) {
            const searchParamsSet = new Set(searchParamSortOrder.match(/[^,]+,[^,]+/g))
            const sortOrderSet = new Set(sortOrder.map(pair => pair.join(',')))

            // Для каждой пары из sortOrder, которая не содержится в searchParamSortOrder
            sortOrder.forEach(pair => {
                const pairString = pair.join(',')
                if (!searchParamsSet.has(pairString)) {
                    filterPartsBySortOrder(pair[0], 'NONE')
                }
            })

            // Для каждой пары из searchParamSortOrder, которая не содержится в sortOrder
            searchParamsSet.forEach(param => {
                if (!sortOrderSet.has(param)) {
                    const [field, order] = param.split(',')
                    filterPartsBySortOrder(field, order)
                }
            })
        } else if (!searchParamSortOrder) {
            for (const field of sortOrder) {
                filterPartsBySortOrder(field[0], 'NONE')
            }
        }

        setSearchParamsLoaded(1)
    }, [searchParams])

    // Загрузка параметров в строку поиска
    useEffect(() => {
        if (!searchParamsLoaded) {
            return
        }

        // Текущие параметры
        const currentPage = page > 1 ? `${page}` : null
        const currentMarkId = markId ? `${markId}` : null
        const currentModelId = modelId ? `${modelId}` : null
        const currentModificationId = modificationId ? `${modificationId}` : null
        const currentBodyTypeId = bodyTypeId ? `${bodyTypeId}` : null
        const currentMileageFrom = mileageFrom ? `${mileageFrom}` : null
        const currentMileageTo = mileageTo ? `${mileageTo}` : null
        const currentPriceFrom = priceFrom ? `${priceFrom}` : null
        const currentPriceTo = priceTo ? `${priceTo}` : null
        const currentPartsCntFrom = partsCntFrom ? `${partsCntFrom}` : null
        const currentPartsCntTo = partsCntTo ? `${partsCntTo}` : null
        const currentSortOrder = sortOrder.length ? sortOrder : null

        // Проверяем, изменились ли параметры строки поиска
        const pageChanged = searchParamPage !== currentPage
        const markIdChanged = searchParamMarkId !== currentMarkId
        const modelIdChanged = searchParamModelId !== currentModelId
        const modificationIdChanged = searchParamModificationId !== currentModificationId
        const bodyTypeIdChanged = searchParamBodyTypeId !== currentBodyTypeId
        const mileageFromChanged = searchParamMileageFrom !== currentMileageFrom
        const mileageToChanged = searchParamMileageTo !== currentMileageTo
        const priceFromChanged = searchParamPriceFrom !== currentPriceFrom
        const priceToChanged = searchParamPriceTo !== currentPriceTo
        const partsCntFromChanged = searchParamPartsCntFrom !== currentPartsCntFrom
        const partsCntToChanged = searchParamPartsCntTo !== currentPartsCntTo

        let sortOrderChanged = false
        if (searchParamSortOrder) {
            const searchParamsSet = new Set(searchParamSortOrder.match(/[^,]+,[^,]+/g))
            const sortOrderSet = new Set(sortOrder.map(pair => pair.join(',')))

            sortOrder.forEach(pair => {
                const pairString = pair.join(',')
                if (!searchParamsSet.has(pairString)) {
                    sortOrderChanged = true
                    return
                }
            })

            searchParamsSet.forEach(param => {
                if (!sortOrderSet.has(param)) {
                    sortOrderChanged = true
                    return
                }
            })
        } else if (!searchParamSortOrder && currentSortOrder) {
            sortOrderChanged = true
        }

        const searchParamsChanged =
            pageChanged ||
            markIdChanged ||
            modelIdChanged ||
            priceFromChanged ||
            priceToChanged ||
            modificationIdChanged ||
            bodyTypeIdChanged ||
            mileageFromChanged ||
            mileageToChanged ||
            partsCntFromChanged ||
            partsCntToChanged ||
            sortOrderChanged

        if (searchParamsChanged) {
            setSearchParams({
                ...(page > 1 ? { p: '' + page } : {}),
                ...(markId ? { markId: '' + markId } : {}),
                ...(modelId ? { modelId: '' + modelId } : {}),
                ...(modificationId ? { modificationId: '' + modificationId } : {}),
                ...(bodyTypeId ? { bodyTypeId: '' + bodyTypeId } : {}),
                ...(mileageFrom ? { mileageFrom: '' + mileageFrom } : {}),
                ...(mileageTo ? { mileageTo: '' + mileageTo } : {}),
                ...(priceFrom ? { priceFrom: '' + priceFrom } : {}),
                ...(priceTo ? { priceTo: '' + priceTo } : {}),
                ...(partsCntFrom ? { partsCntFrom: '' + partsCntFrom } : {}),
                ...(partsCntTo ? { partsCntTo: '' + partsCntTo } : {}),
                ...(sortOrder && sortOrder.length !== 0 ? { sortOrder: '' + sortOrder } : {}),
            })
        }

        // load cars list data
        dispatch(
            LoadCarList(
                page,
                perPage,
                markId,
                modelId,
                modificationId,
                bodyTypeId,
                partsCntRange && { from: partsCntFrom, to: partsCntTo },
                priceRange && { from: priceFrom, to: priceTo },
                mileageRange && { from: mileageFrom, to: mileageTo },
                sortOrder,
            ),
        )
    }, [
        searchParamsLoaded,
        page,
        perPage,
        markId,
        modelId,
        modificationId,
        bodyTypeId,
        partsCntFrom,
        partsCntTo,
        priceFrom,
        priceTo,
        mileageFrom,
        mileageTo,
        sortOrder,
    ])

    const openEditModal = (car: Car) => {
        navigateWithParams(car.id)

        setCurrentCar(car)
        setIsModalOpen(true)
    }

    const handleClose = () => {
        navigateWithParams()
        setIsModalOpen(false)
    }

    const getCarById = async () => {
        if (params.carId) {
            const car = await DatahubApiService.getCarByCarId(params.carId)

            if (car) {
                openEditModal(car)
            }
        }
    }

    const openDeleteModal = (car: Car) => {
        setCurrentCar(car)
        setIsDeleteModalOpen(true)
    }

    // open modal by carId
    useEffect(() => {
        if (params.carId) {
            getCarById()
        } else {
            setIsModalOpen(false)
        }
    }, [params.carId])

    // Сброс фильтров при уходе со страницы
    useEffect(
        () => () => {
            dispatch({
                type: storeDispatchTypes.resetFiltersCars,
            })
        },
        [],
    )

    return (
        <div className={styles.wrap}>
            <div className={styles.titleWrap}>
                <SalesText
                    text={'Автомобили'}
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.xxl}
                    letterSpacing={-0.02}
                />
                <SalesButton
                    borderRadius={10}
                    backgroundColor={colors.blueLight}
                    paddingHorizontalPixels={20}
                    paddingVerticalPixels={6}
                    onClick={() => navigate(getAdminNavigationPath(adminRouteAlias.newCar.location))}
                >
                    <>
                        <PlusLg size={24} color={colors.white} />
                        <SalesText
                            text={'Добавить авто'}
                            marginLeftPixels={10}
                            fontSize={fontSizes.s}
                            color={colors.white}
                        />
                    </>
                </SalesButton>
                <div className={styles.wrapRight}>
                    {!isLoading && cars.length === 0 ? (
                        <></>
                    ) : (
                        <ListPagination
                            page={page}
                            setPage={page =>
                                dispatch({
                                    type: storeDispatchTypes.setPageFilterCars,
                                    value: { page },
                                })
                            }
                            perPage={perPage}
                            itemsCount={itemsCount}
                        />
                    )}
                </div>
            </div>

            {isModalOpen && currentCar && (
                <ModalEditCar car={currentCar} isOpen={isModalOpen} closeModal={() => handleClose()} />
            )}
            {isDeleteModalOpen && currentCar && (
                <ModalDeleteCar
                    car={currentCar}
                    isOpen={isDeleteModalOpen}
                    closeModal={() => setIsDeleteModalOpen(false)}
                />
            )}

            <Table striped hover>
                <thead>
                    <tr>
                        <th scope="col" style={colWidth(55)}>
                            Фото
                        </th>
                        <th scope="col" style={colWidth(100)}>
                            <TableHeadMarkSearchCars />
                        </th>
                        <th scope="col" style={colWidth(200)}>
                            <TableHeadModelSearchCars />
                        </th>
                        <th scope="col" style={colWidth(75)}>
                            Год
                            <ColumnSort
                                iconType={SortIconType.NUMERIC}
                                sortField={SortFieldCars.YEAR}
                                slice={'carList'}
                            />
                        </th>
                        <th scope="col" style={colWidth(155)}>
                            <TableHeadModificationSearchCars />
                        </th>
                        <th scope="col" style={colWidth(150)}>
                            <TableHeadBodyTypeSearchCars />
                        </th>
                        <th scope="col" style={colWidth(88)}>
                            <TableHeadMileageRangeCars initialFromVal={mileageFrom} initialToVal={mileageTo} />
                        </th>
                        {canShowFinanceInfo && (
                            <>
                                <th scope="col" style={colWidth(96)}>
                                    <TableHeadPriceRangeCars
                                        initialFromVal={priceFrom}
                                        initialToVal={priceTo}
                                        initialTableHead={'Закуп'}
                                    />
                                </th>
                                <th scope="col" style={colWidth(55)} className={styles.alignRightCell}>
                                    Продажи
                                </th>
                            </>
                        )}
                        <th scope="col" style={colWidth(113)} className={styles.alignRightCell}>
                            Создано
                            <ColumnSort
                                iconType={SortIconType.NUMERIC}
                                sortField={SortFieldCars.CREATED_AT}
                                slice={'carList'}
                                reverseSort
                            />
                        </th>
                        <th scope="col" style={colWidth(79)}>
                            <TableHeadPartsCountRangeCars initialFromVal={partsCntFrom} initialToVal={partsCntTo} />
                        </th>
                        <th scope="col" style={colWidth(60)}>
                            Действия
                        </th>
                    </tr>
                </thead>
                {isLoading || !cars ? (
                    <ListSkeleton rowCount={9} columnCount={canShowFinanceInfo ? 12 : 10} marginVerticalRem={0.21} />
                ) : (
                    <tbody>
                        {cars.map((car, index) => (
                            <tr key={index} className={styles.row}>
                                <td scope="row">
                                    <CellThumbCarousel pictures={car.images} />
                                </td>
                                <td scope="row" onClick={e => goToCarDetails(e, car)}>
                                    {car.markTitle}
                                </td>
                                <td scope="row" onClick={e => goToCarDetails(e, car)}>
                                    {car.modelTitle}
                                </td>
                                <td scope="row" onClick={e => goToCarDetails(e, car)}>
                                    {car.year}
                                </td>
                                <td scope="row" onClick={e => goToCarDetails(e, car)}>
                                    {car.modificationTitle ? (
                                        <div>
                                            <Badge text={car.modificationTitle} color="#ddd" fontColor="#555" />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {car.vinCode ? (
                                        <div>
                                            <Badge text={'vin: ' + car.vinCode} color="#aaa" />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </td>
                                <td scope="row" onClick={e => goToCarDetails(e, car)}>
                                    {car.bodyTypeTitle}
                                </td>
                                <td scope="row" onClick={e => goToCarDetails(e, car)}>
                                    {car.mileage ? car.mileage.toLocaleString('ru-RU') : ''}
                                </td>
                                {canShowFinanceInfo && (
                                    <>
                                        <td
                                            scope="row"
                                            onClick={e => goToCarDetails(e, car)}
                                            className={styles.alignRightCell}
                                        >
                                            {car.price ? car.price.toLocaleString('ru-RU') : ''}
                                        </td>
                                        <td
                                            scope="row"
                                            onClick={e => goToCarSoldPartList(e, car)}
                                            className={styles.alignRightCell}
                                        >
                                            {car.sumSoldParts ? (
                                                <CellSoldPercent
                                                    soldPercent={car.soldPartsPercent}
                                                    carPrice={car.price}
                                                    sumSoldParts={car.sumSoldParts}
                                                    leftPartsSum={car.leftPartsSum}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </>
                                )}
                                <td scope="row" className={styles.alignRightCell} onClick={e => goToCarDetails(e, car)}>
                                    <div>
                                        <Badge text={formatDate(car.createdAt)} color="#ddd" fontColor="#555" />
                                    </div>
                                    {+countDaysInStock(car.createdAt) > 10 ? (
                                        <div>
                                            <Badge
                                                text={
                                                    countDaysInStock(car.createdAt) +
                                                    ' ' +
                                                    num_word(+countDaysInStock(car.createdAt), ['день', 'дня', 'дней'])
                                                }
                                                color="#eee"
                                                fontColor="#777"
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </td>
                                <td scope="row" className={styles.alignRightCell} onClick={e => goToCarDetails(e, car)}>
                                    {car.partsCnt}
                                </td>
                                <td scope="row">
                                    <CellActionsCars
                                        car={car}
                                        isSafari={browserName === 'Safari'}
                                        editModal={() => openEditModal(car)}
                                        deleteModal={() => openDeleteModal(car)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}
            </Table>

            {!isLoading && cars.length === 0 ? (
                <ListNoItems slice={'carList'} />
            ) : (
                <ListPagination
                    page={page}
                    setPage={page =>
                        dispatch({
                            type: storeDispatchTypes.setPageFilterCars,
                            value: { page },
                        })
                    }
                    perPage={perPage}
                    itemsCount={itemsCount}
                    justifyContent={'flex-end'}
                />
            )}
        </div>
    )
}

export default CarList
