import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

// update certaint field.

const setPartExtraDataFieldValue =
    (partId: string, extraDataField: string, extraDataValue: any) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<boolean> => {
        const { userData } = getState()

        try {
            const {
                data: { status, extraData },
            } = await apiInstance.post(
                '/setPartExtraDataFieldValue',
                {
                    userId: userData.smsAuth.userId,
                    partId,
                    extraDataField,
                    extraDataValue,
                },
                {
                    headers: {
                        Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
                    },
                },
            )
            if (status === 'ok') {
                // console.log('storeDispatchTypes.updatePartExtraDataFieldValue',extraDataField, extraDataValue)
                dispatch({
                    type: storeDispatchTypes.updatePartExtraDataFieldValue,
                    value: {
                        id: partId,
                        extraDataField: extraDataField,
                        extraDataValue: extraDataValue,
                    },
                })
                return true
            }
            return false
        } catch (e) {
            console.error('setPartExtraDataFieldValue failed', e)
            return false
        }
    }

export default setPartExtraDataFieldValue
