import React from 'react'

import { colors, fontSizes } from '../../../constants/salesStyles'
import useHoverHandler from '../../../hooks/sales/useHoverHandler'
import { SelectOptionLocal } from '../../../types/common/commonTypes'
import Badge from '../../_atoms/Badge/Badge'
import SalesText from '../../_atoms/SalesText/SalesText'

import styles from './styles.module.scss'

interface IProps {
    name: string
    value: string | number
    subName?: string
    subValue?: string | number
    updateField: (option: SelectOptionLocal) => void
    hideOptions: () => void
    isActive: boolean
    fontSize: fontSizes
    fontSizeSubName?: fontSizes
    subText?: string
    badgeNames?: string[]
    color?: colors
    colorHover?: colors
    backgroundColor?: colors
    backgroundColorHover?: colors
    beforeText?: JSX.Element
    option: SelectOptionLocal
}

const SalesSelectSearchOption = ({
    name,
    value,
    updateField,
    hideOptions,
    isActive,
    fontSize,
    subValue,
    subName,
    fontSizeSubName,
    subText,
    badgeNames,
    color = colors.white,
    colorHover = colors.white,
    backgroundColor = colors.purple,
    backgroundColorHover = colors.purple,
    beforeText,
    option,
}: IProps) => {
    const [isHover, hoverOn, hoverOff] = useHoverHandler()

    const setOption = () => {
        updateField(option)
        hideOptions()
    }

    return (
        <div onMouseEnter={hoverOn} onMouseLeave={hoverOff} className={styles.wrap} onClick={setOption}>
            <SalesText
                text={name}
                fontSize={fontSize}
                backgroundColor={isActive ? backgroundColor : isHover ? backgroundColorHover : colors.transparent}
                color={isActive ? color : isHover ? colorHover : colors.black}
                marginTopPixels={7}
                marginBottomPixels={subName || badgeNames?.length ? 0 : 5}
                marginLeftPixels={-20}
                marginRightPixels={-20}
                paddingLeftPixels={20}
                paddingRightPixels={20}
                subText={subText}
            />
            {!subName ? null : (
                <SalesText
                    text={subName}
                    fontSize={fontSizeSubName}
                    backgroundColor={isActive ? backgroundColor : isHover ? backgroundColorHover : colors.transparent}
                    color={isActive ? color : isHover ? colorHover : colors.grayLight}
                    marginTopPixels={0}
                    marginBottomPixels={badgeNames?.length ? 0 : 5}
                    marginLeftPixels={-20}
                    marginRightPixels={-20}
                    paddingLeftPixels={20}
                    paddingRightPixels={20}
                    paddingBottomPixels={3}
                    maxLines={2}
                    beforeText={beforeText}
                />
            )}
            {badgeNames?.length && (
                <div
                    className={styles.badge}
                    style={{
                        fontSize: fontSize ? fontSize : fontSizes.m,
                        backgroundColor: isActive
                            ? backgroundColor
                            : isHover
                              ? backgroundColorHover
                              : colors.transparent,
                    }}
                >
                    {badgeNames.map((text, index) => (
                        <div key={index}>
                            <Badge text={text} color="#aaa" />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default SalesSelectSearchOption
