import React from 'react'
import { XCircleFill } from 'react-bootstrap-icons'
import { useDispatch } from 'react-redux'

import { useSelector } from '../../../modules/store/customSelector'
import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import {
    SortFieldActionLogs,
    SortFieldCars,
    SortFieldParts,
    SortIconColor,
    SortIconType,
    SortOrder,
} from '../../../types/common/commonTypes'
import SortIcon from '../../_atoms/SortIcon/SortIcon'

import styles from './styles.module.scss'

interface IProps {
    iconType: SortIconType
    sortField: SortFieldParts | SortFieldCars | SortFieldActionLogs
    reverseSort?: boolean
    slice: 'partList' | 'carList' | 'actionLogList'
    isResetButton?: boolean
    handleSortChange?: (isActive: boolean) => void
}

const sortOrderNext = (sortOrderCurrent: string) => {
    switch (true) {
        case sortOrderCurrent === SortOrder.NONE:
            return SortOrder.DESC
        case sortOrderCurrent === SortOrder.DESC:
            return SortOrder.ASC
        case sortOrderCurrent === SortOrder.ASC:
            return SortOrder.NONE
        default:
            return sortOrderCurrent
    }
}

const iconColor = (sortOrderCurrent: string) => {
    if (sortOrderCurrent === SortOrder.NONE) {
        return SortIconColor.INACTIVE
    } else {
        return SortIconColor.ACTIVE
    }
}

const ColumnSort = ({ iconType, sortField, reverseSort, slice, isResetButton = true, handleSortChange }: IProps) => {
    const partListSortOrder = useSelector(state => state.partList.sortOrder)
    const carListSortOrder = useSelector(state => state.carList.sortOrder)
    const actionLogListSortOrder = useSelector(state => state.actionLogList.sortOrder)

    let sortOrderCurrentEl
    let sortOrderCurrentIndex = 1
    let actionType: storeDispatchTypes

    switch (slice) {
        case 'partList':
            sortOrderCurrentEl = partListSortOrder.find(item => item[0] === sortField)
            sortOrderCurrentIndex += partListSortOrder.findIndex(item => item[0] === sortField)
            actionType = storeDispatchTypes.setPartListSort
            break
        case 'carList':
            sortOrderCurrentEl = carListSortOrder.find(item => item[0] === sortField)
            sortOrderCurrentIndex += carListSortOrder.findIndex(item => item[0] === sortField)
            actionType = storeDispatchTypes.setCarListSort
            break
        case 'actionLogList':
            sortOrderCurrentEl = actionLogListSortOrder.find(item => item[0] === sortField)
            sortOrderCurrentIndex += actionLogListSortOrder.findIndex(item => item[0] === sortField)
            actionType = storeDispatchTypes.setActionLogListSort
            break
    }

    const sortOrderCurrent = sortOrderCurrentEl ? sortOrderCurrentEl[1] : SortOrder.NONE
    const sortOrder = sortOrderNext(sortOrderCurrent)

    const dispatch = useDispatch()
    const changeSort = () => {
        dispatch({
            type: actionType,
            value: {
                sortField,
                sortOrder,
            },
        })
        if (sortOrder !== SortOrder.NONE && handleSortChange) {
            handleSortChange(true)
        }
    }

    const resetSort = () => {
        dispatch({
            type: actionType,
            value: {
                sortField,
                sortOrder: SortOrder.NONE,
            },
        })
    }

    return (
        <span className={styles.wrap}>
            <span onClick={changeSort} className={styles.sortIcon}>
                <SortIcon
                    sortOrder={sortOrderCurrent}
                    iconType={iconType}
                    color={iconColor(sortOrderCurrent)}
                    size={18}
                    reverseSort={reverseSort}
                />
            </span>
            {sortOrderCurrent !== SortOrder.NONE && isResetButton && (
                <>
                    <span className={styles.clearIcon} onClick={resetSort}>
                        <XCircleFill color="grey" size={14} />
                    </span>
                    <span className={styles.sortIndex}>{sortOrderCurrentIndex}</span>
                </>
            )}
        </span>
    )
}

export default ColumnSort
