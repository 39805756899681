import { Reducer } from 'redux'

import { defaultCustomizableColumns } from '../../../constants/defaultCustomizableColumns'
import {
    ApiOption,
    RangeInput,
    SelectOptionLocal,
    SortFieldParts,
    SortOrder,
    TCurrencyKeys,
} from '../../../types/common/commonTypes'
import { IncomingInvoice } from '../../../types/document/documentTypes'
import { Applicability, MarketplacesKeys, Part, PartType, SoldPart } from '../../../types/part/partTypes'
import { storeDispatchTypes } from '../storeDispatchTypes'

interface PartList {
    data: Part[]
    total: number
    dataCount: number
    draftCount: number
    archivedCount: number
    soldCount: number
}

export const defaultPartList: PartList = {
    data: [],
    total: 0,
    dataCount: 0,
    draftCount: 0,
    archivedCount: 0,
    soldCount: 0,
}

interface ApiCategory {
    id: number
    title: string
    count: number
}

enum RangeFields {
    priceRange = 'priceRange',
    yearRange = 'yearRange',
    dateRange = 'dateRange',
}

export type Column = {
    id: string
    label: string
}

type PartNumbersWithDescription = SelectOptionLocal & {
    description: string
}

export interface stateTypeParts {
    partList: PartList
    isLoading: {
        partList: boolean
        customizableColumns: boolean
    }
    sortOrder: [SortFieldParts, SortOrder][]
    categories: SelectOptionLocal[]
    marks: SelectOptionLocal[]
    models: SelectOptionLocal[]
    partNumbers: SelectOptionLocal[]
    partNumbersWithDescription: PartNumbersWithDescription[]
    partTitles: SelectOptionLocal[]
    partTypes: SelectOptionLocal[]
    partBrands: SelectOptionLocal[]
    tiresBrands: SelectOptionLocal[]
    tiresModels: SelectOptionLocal[]
    partGroups: SelectOptionLocal[]
    oilsBrands: SelectOptionLocal[]
    oilsVolumes: SelectOptionLocal[]
    oilsSAE: SelectOptionLocal[]
    oilsACEA: SelectOptionLocal[]
    oilsAPI: SelectOptionLocal[]
    oilsOEMOil: SelectOptionLocal[]
    oilsATF: SelectOptionLocal[]
    oilsASTM: SelectOptionLocal[]
    coolantColors: SelectOptionLocal[]
    brakeDOT: SelectOptionLocal[]
    radioBrands: SelectOptionLocal[]
    amplifierBrands: SelectOptionLocal[]
    audioBrands: SelectOptionLocal[]
    audioSizes: SelectOptionLocal[]

    sellPartModal: { partId: string; isOpen: boolean }

    applicability: {
        applicabilitySelected: Applicability[]
        applicabilitySuggestion: Applicability[]
    }
    partType?: PartType

    loaders: {
        partTypes: boolean
        isPartSaving: boolean
        isPartCreated: boolean
        partBrands: boolean
        tiresBrands: boolean
        tiresModels: boolean
        partGroups: boolean
        partNumbers: boolean
        oilsBrands: boolean
        oilsVolumes: boolean
        oilsSAE: boolean
        oilsACEA: boolean
        oilsAPI: boolean
        oilsOEMOil: boolean
        oilsATF: boolean
        oilsASTM: boolean
        coolantColors: boolean
        brakeDOT: boolean
        currentCurrency: boolean
        radioBrands: boolean
        amplifierBrands: boolean
        audioBrands: boolean
        audioSizes: boolean
    }

    filters: {
        // alias for groupId
        categoryId?: number[]
        markId?: number[]
        modelId?: number[]
        partNumber?: string[]
        partId?: string[]
        car?: {
            carId: string[]
            markTitle: string
            modelTitle: string
            year: number
        }
        // alias for search
        partName?: string
        [RangeFields.priceRange]?: RangeInput
        [RangeFields.yearRange]?: RangeInput
        filterDate?: string
        filterDateField?: string
        filterVendorCode?: string
        warehouseId?: string
        [RangeFields.dateRange]?: RangeInput
        page: number
        perPage: number
        document?: {
            documentId?: string
        }
        withPrice?: boolean
        withPhoto?: boolean
        withDescription?: boolean
        withPartNumber?: boolean
        withMarkModelModificationAndTypeId?: boolean
        withCarId?: boolean
        withAvitoFullInfo?: boolean
        marketplace?: MarketplacesKeys
    }

    selectedParts: {
        selectAll: boolean
        parts: Part[]
    }

    currentPart?: Part
    customizableColumns: Column[]
    isPartSavedSuccessfully?: {
        partId: string
        success: boolean
    }
    currentCurrency: TCurrencyKeys
}

const initialState: stateTypeParts = {
    partList: defaultPartList,
    isLoading: {
        partList: false,
        customizableColumns: false,
    },
    sellPartModal: { partId: '', isOpen: false },
    sortOrder: [],
    categories: [],
    marks: [],
    models: [],
    partNumbers: [],
    partNumbersWithDescription: [],
    partTitles: [],
    partTypes: [],
    partBrands: [],
    tiresBrands: [],
    tiresModels: [],
    partGroups: [],
    oilsBrands: [],
    oilsVolumes: [],
    oilsSAE: [],
    oilsACEA: [],
    oilsAPI: [],
    oilsOEMOil: [],
    oilsATF: [],
    oilsASTM: [],
    coolantColors: [],
    brakeDOT: [],
    radioBrands: [],
    amplifierBrands: [],
    audioBrands: [],
    audioSizes: [],
    applicability: {
        applicabilitySelected: [],
        applicabilitySuggestion: [],
    },
    filters: {
        page: 1,
        perPage: 50,
    },
    loaders: {
        partTypes: false,
        isPartSaving: false,
        isPartCreated: false,
        tiresBrands: false,
        partBrands: false,
        tiresModels: false,
        partGroups: false,
        partNumbers: false,
        oilsBrands: false,
        oilsVolumes: false,
        oilsSAE: false,
        oilsACEA: false,
        oilsAPI: false,
        oilsOEMOil: false,
        oilsATF: false,
        oilsASTM: false,
        coolantColors: false,
        brakeDOT: false,
        currentCurrency: false,
        radioBrands: false,
        amplifierBrands: false,
        audioBrands: false,
        audioSizes: false,
    },
    selectedParts: {
        selectAll: false,
        parts: [],
    },
    customizableColumns: defaultCustomizableColumns,
    currentCurrency: 'RUR',
}

const createPartListReducer: Reducer<stateTypeParts> = (state = initialState, action) => {
    const getUpdatedPartIndex = (partId: string) => state.partList.data.findIndex(part => part.id === partId)

    // update certain field. not used now
    const updatePartExtraDataFieldValueById = (
        partId: string,
        extraDataField: string,
        extraDataValue: string | number | boolean,
    ) =>
        state.partList.data.map((part, index) => {
            if (index === getUpdatedPartIndex(partId)) {
                const extraData: { [key: string]: any } = part.extraData || {}
                extraData[extraDataField] = extraDataValue
                return { ...part, ['extraData']: extraData }
            }
            return { ...part }
        })
    const updatePartExtraDataFieldValueArrayById = (partId: string, extraDataField: string, extraDataValue: SoldPart) =>
        state.partList.data.map((part, index) => {
            if (index === getUpdatedPartIndex(partId)) {
                const extraData: { [key: string]: any } = part.extraData || {}
                extraData[extraDataField] = [...(extraData[extraDataField] || []), extraDataValue]
                return { ...part, ['extraData']: extraData }
            }
            return { ...part }
        })
    const updateDataFieldById = (partId: string, field: string, value: string | number | boolean) =>
        state.partList.data.map((part, index) => {
            if (index === getUpdatedPartIndex(partId)) {
                return { ...part, [field]: value }
            }
            return { ...part }
        })
    const updateDataFieldsById = (partId: string, fields: Partial<Part>) =>
        state.partList.data.map((part, index) => {
            if (index === getUpdatedPartIndex(partId)) {
                return { ...part, ...fields }
            }
            return { ...part }
        })
    const updatePartSoldStatusById = (
        partId: string,
        isSold: boolean,
        pendingRemoval: boolean,
        updatePendingOnly = false,
    ) =>
        state.partList.data.map((part, index) => {
            if (index === getUpdatedPartIndex(partId)) {
                // Проверяем флаг `pendingRemoval`, если требуется обновить только такие элементы
                if (updatePendingOnly && !part.pendingRemoval) {
                    return part
                }

                const { extraData } = part
                if (!isSold && extraData?.soldQtyArr) {
                    extraData.soldQtyArr = undefined
                }
                return { ...part, isSold, pendingRemoval, extraData }
            }
            return { ...part }
        })
    const setRangeByField = (field: RangeFields) => {
        const { from, to } = action.value
        if (from || to) {
            const newRange = {
                from: from ? Number(from) : undefined,
                to: to ? Number(to) : undefined,
            }
            return { ...state, filters: { ...state.filters, [field]: newRange } }
        }
        const filters = { ...state.filters }
        if (field in filters) {
            delete filters[field]
        }
        return { ...state, filters }
    }
    const removeItemFromList = (field: 'isDeleted' | 'isSold' | 'isArchived') => {
        const draftList = [...state.partList.data]
        const filteredList = draftList.filter(item => !item[field] || !item.pendingRemoval)
        return { ...state, partList: { ...state.partList, data: filteredList } }
    }
    const removeItemById = (id: string) => {
        const draftList = [...state.partList.data]
        const filteredList = draftList.filter(item => item.id !== id || !item.pendingRemoval)
        return { ...state, partList: { ...state.partList, data: filteredList } }
    }
    switch (action.type) {
        case storeDispatchTypes.setPartList: {
            return {
                ...state,
                partList: action.value,
            }
        }
        case storeDispatchTypes.setPartListLoading: {
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    partList: action.value,
                },
            }
        }
        case storeDispatchTypes.setCustomizableColumnsLoading: {
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    customizableColumns: action.value,
                },
            }
        }
        case storeDispatchTypes.setSellPartModal: {
            return {
                ...state,
                sellPartModal: action.value,
            }
        }
        case storeDispatchTypes.setPartPriceLoading: {
            return {
                ...state,
                isPartPriceLoading: action.value,
            }
        }
        case storeDispatchTypes.setPartCarDataLoading: {
            return {
                ...state,
                isPartCarDataLoading: action.value,
            }
        }
        case storeDispatchTypes.updatePartPrice: {
            const draftData = updateDataFieldById(action.value.id, 'price', parseInt(action.value.price, 10))
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.updatePartCarData: {
            const draftData = updateDataFieldsById(action.value.id, action.value.carData)
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.setPartSoldStatus: {
            const draftData = updatePartSoldStatusById(
                action.value.id,
                action.value.isSold,
                true,
                action.value.updatePendingOnly,
            )
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.setPartDeletedStatus: {
            const draftData = updateDataFieldsById(action.value.id, {
                isDeleted: action.value.isDeleted,
                pendingRemoval: true,
            })
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.setPartArchivedStatus: {
            const draftData = updateDataFieldsById(action.value.id, {
                isArchived: action.value.isArchived,
                pendingRemoval: true,
            })
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.removeDeletedPartFormList: {
            return removeItemById(action.value.id)
        }
        case storeDispatchTypes.removeArchivedPartFormList: {
            return removeItemById(action.value.id)
        }
        case storeDispatchTypes.removePartFormListByPartId: {
            return removeItemById(action.value.id)
        }
        case storeDispatchTypes.updatePartDescription: {
            const draftData = updateDataFieldById(action.value.id, 'description', action.value.description)
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.updatePartCustomTitle: {
            const draftData = updateDataFieldById(action.value.id, 'customTitle', action.value.customTitle)
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.updatePartExtraDataValue: {
            const draftData = updateDataFieldById(action.value.id, 'extraData', action.value.extraData)
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        // update certaint field. not used now
        case storeDispatchTypes.updatePartExtraDataFieldValue: {
            const draftData = updatePartExtraDataFieldValueById(
                action.value.id,
                action.value.extraDataField,
                action.value.extraDataValue,
            )
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.updatePartNumber: {
            const draftData = updateDataFieldById(action.value.id, 'partNumber', action.value.partNumber)
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.updatePartCondition: {
            const draftData = updateDataFieldById(action.value.id, 'conditionId', action.value.conditionId)
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.updatePartTypeId: {
            const draftData = updateDataFieldById(action.value.id, 'typeId', action.value.typeId)
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.updatePartTitle: {
            const draftData = updateDataFieldById(action.value.id, 'title', action.value.title)
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.updatePartGroupId: {
            const draftData = updateDataFieldById(action.value.id, 'groupId', action.value.groupId)
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.updatePartImages: {
            const draftData = state.partList.data.map((part, index) => {
                if (index === getUpdatedPartIndex(action.value.partId)) {
                    return {
                        ...part,
                        images: [...part.images, { id: action.value.id, publicUrl: action.value.publicUrl }],
                    }
                }
                return { ...part }
            })
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.updatePartExtraDataQuantity: {
            const draftData = updatePartExtraDataFieldValueById(action.value.id, 'qty', action.value.qty)
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.updatePartExtraDataSoldQuantityArr: {
            const draftData = updatePartExtraDataFieldValueArrayById(
                action.value.id,
                'soldQtyArr',
                action.value.soldQtyArr,
            )
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.deletePartImage: {
            const draftData = state.partList.data.map((part, index) => {
                if (index === getUpdatedPartIndex(action.value.id)) {
                    return { ...part, images: part.images.filter(image => image.id !== action.value.imageId) }
                }
                return { ...part }
            })
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.reorderPartImages: {
            const draftData = updateDataFieldById(action.value.id, 'images', action.value.images)
            return { ...state, partList: { ...state.partList, data: draftData } }
        }
        case storeDispatchTypes.setPartListSort: {
            const draftSortOrder = [...state.sortOrder]
            const currentSortOrderIndex = draftSortOrder.findIndex(item => item[0] === action.value.sortField)
            if (currentSortOrderIndex < 0) {
                draftSortOrder.push([action.value.sortField, action.value.sortOrder])
            } else {
                draftSortOrder[currentSortOrderIndex] = [action.value.sortField, action.value.sortOrder]
            }
            const finalSortOrder = draftSortOrder.filter(item => item[1] !== SortOrder.NONE)
            return { ...state, sortOrder: finalSortOrder }
        }
        case storeDispatchTypes.setPartNameTextFilterParts: {
            return { ...state, filters: { ...state.filters, partName: action.value.partName } }
        }
        case storeDispatchTypes.setCategoriesParts: {
            const categories = action.value.categories.map((item: ApiCategory) => ({
                value: String(item.id),
                name: item.title,
                subText: `(${item.count})`,
            }))
            return { ...state, categories }
        }
        case storeDispatchTypes.setCategoryFilterParts: {
            return {
                ...state,
                filters: { ...state.filters, categoryId: action.value.filterId && [action.value.filterId] },
            }
        }
        case storeDispatchTypes.setMarksParts: {
            const marks = action.value.categories.map((item: ApiCategory) => ({
                value: String(item.id),
                name: item.title,
                subText: `(${item.count})`,
            }))
            return { ...state, marks }
        }
        case storeDispatchTypes.setMarkFilterParts: {
            return { ...state, filters: { ...state.filters, markId: action.value.filterId && [action.value.filterId] } }
        }
        case storeDispatchTypes.setCarFilterParts: {
            return { ...state, filters: { ...state.filters, car: action.value.car } }
        }
        case storeDispatchTypes.setDocumentFilterParts: {
            return { ...state, filters: { ...state.filters, document: action.value.document } }
        }
        case storeDispatchTypes.setFilterDateFilterParts: {
            return { ...state, filters: { ...state.filters, filterDate: action.value.filterDate } }
        }
        case storeDispatchTypes.setFilterDateFieldFilterParts: {
            return { ...state, filters: { ...state.filters, filterDateField: action.value.filterDateField } }
        }
        case storeDispatchTypes.setFilterWarehouseIdParts: {
            return { ...state, filters: { ...state.filters, warehouseId: action.value.warehouseId } }
        }
        case storeDispatchTypes.setModelsParts: {
            const models = action.value.models?.map((model: ApiOption) => ({
                value: model.id,
                name: model.title,
                subText: model.count ? ` (${model.count})` : '',
            }))
            return { ...state, models }
        }
        case storeDispatchTypes.setModelFilterParts: {
            return {
                ...state,
                filters: { ...state.filters, modelId: action.value.filterId && [action.value.filterId] },
            }
        }
        case storeDispatchTypes.setPartNumbersParts: {
            const partNumbers = action.value.partNumbers?.map((partNumber: ApiOption) => ({
                value: partNumber.title,
                name: partNumber.title,
            }))
            return { ...state, partNumbers }
        }
        case storeDispatchTypes.setPartNumbersPartsWithDescription: {
            const partNumbersWithDescription = action.value.partNumbers?.map((partNumber: ApiOption) => ({
                value: partNumber.title + partNumber.description,
                name: partNumber.title,
                subText: partNumber.markTitle
                    ? `\u2014 ${[
                          partNumber.markTitle,
                          partNumber.modelTitle,
                          partNumber.year,
                          partNumber.modificationTitle,
                          partNumber.vinCode,
                      ]
                          .filter(Boolean)
                          .join(' ')}`
                    : '',
                subName: partNumber.description,
                partData: {
                    typeId: partNumber.typeId,
                    title: partNumber.partTitle,
                    customTitle: partNumber.customTitle,
                    description: partNumber.description,
                },
            }))
            return { ...state, partNumbersWithDescription }
        }
        case storeDispatchTypes.setPartNumberFilterParts: {
            return {
                ...state,
                filters: { ...state.filters, partNumber: action.value.filterId && [action.value.filterId] },
            }
        }
        case storeDispatchTypes.setPartTitleFilterParts: {
            return { ...state, filters: { ...state.filters, partId: action.value.filterId && [action.value.filterId] } }
        }
        case storeDispatchTypes.setPartIdsFilterParts: {
            return { ...state, filters: { ...state.filters, partId: action.value.partIds } }
        }
        case storeDispatchTypes.setPartTitlesParts: {
            const partTitles = action.value.partTitles?.map((partTitle: ApiOption) => ({
                value: partTitle.id,
                name: partTitle.title + (partTitle.count ? ` (${partTitle.count})` : ''),
            }))
            return { ...state, partTitles }
        }
        case storeDispatchTypes.setAllPartTypesParts: {
            return { ...state, partTypes: action.value.partTypes }
        }
        case storeDispatchTypes.setAllTiresBrands: {
            return { ...state, tiresBrands: action.value.tiresBrands }
        }
        case storeDispatchTypes.setAllPartBrands: {
            return { ...state, partBrands: action.value.partBrands }
        }
        case storeDispatchTypes.setAllTiresModels: {
            return { ...state, tiresModels: action.value.tiresModels }
        }
        case storeDispatchTypes.setAllPartGroups: {
            return { ...state, partGroups: action.value.partGroups }
        }
        case storeDispatchTypes.setAllOilsBrands: {
            return { ...state, oilsBrands: action.value.oilsBrands }
        }
        case storeDispatchTypes.setAllOilsVolumes: {
            return { ...state, oilsVolumes: action.value.oilsVolumes }
        }
        case storeDispatchTypes.setAllOilsSAE: {
            return { ...state, oilsSAE: action.value.oilsSAE }
        }
        case storeDispatchTypes.setAllOilsACEA: {
            return { ...state, oilsACEA: action.value.oilsACEA }
        }
        case storeDispatchTypes.setAllOilsAPI: {
            return { ...state, oilsAPI: action.value.oilsAPI }
        }
        case storeDispatchTypes.setAllOilsOEMOil: {
            return { ...state, oilsOEMOil: action.value.oilsOEMOil }
        }
        case storeDispatchTypes.setAllOilsATF: {
            return { ...state, oilsATF: action.value.oilsATF }
        }
        case storeDispatchTypes.setAllOilsASTM: {
            return { ...state, oilsASTM: action.value.oilsASTM }
        }
        case storeDispatchTypes.setAllCoolantColors: {
            return { ...state, coolantColors: action.value.coolantColors }
        }
        case storeDispatchTypes.setAllBrakeDOT: {
            return { ...state, brakeDOT: action.value.brakeDOT }
        }
        case storeDispatchTypes.setAllRadioBrands: {
            return { ...state, radioBrands: action.value.radioBrands }
        }
        case storeDispatchTypes.setAllAmplifierBrands: {
            return { ...state, amplifierBrands: action.value.amplifierBrands }
        }
        case storeDispatchTypes.setAllAudioBrands: {
            return { ...state, audioBrands: action.value.audioBrands }
        }
        case storeDispatchTypes.setAllAudioSizes: {
            return { ...state, audioSizes: action.value.audioSizes }
        }
        case storeDispatchTypes.resetFiltersParts: {
            return { ...state, filters: { page: 1, perPage: 50 }, sortOrder: [] }
        }
        case storeDispatchTypes.resetCarFilterParts: {
            const filters = { ...state.filters }
            if ('car' in filters) {
                delete filters.car
            }
            return { ...state, filters }
        }
        case storeDispatchTypes.setPriceRangeParts: {
            return setRangeByField(RangeFields.priceRange)
        }
        case storeDispatchTypes.setYearRangeParts: {
            return setRangeByField(RangeFields.yearRange)
        }
        case storeDispatchTypes.setDateRangeParts: {
            return setRangeByField(RangeFields.dateRange)
        }
        case storeDispatchTypes.setPageFilterParts: {
            return { ...state, filters: { ...state.filters, page: action.value.page } }
        }
        case storeDispatchTypes.setWithPriceParts: {
            return { ...state, filters: { ...state.filters, withPrice: action.value.withPrice } }
        }
        case storeDispatchTypes.setWithPhotoParts: {
            return { ...state, filters: { ...state.filters, withPhoto: action.value.withPhoto } }
        }
        case storeDispatchTypes.setWithDescriptionParts: {
            return { ...state, filters: { ...state.filters, withDescription: action.value.withDescription } }
        }
        case storeDispatchTypes.setWithPartNumberParts: {
            return { ...state, filters: { ...state.filters, withPartNumber: action.value.withPartNumber } }
        }
        case storeDispatchTypes.setWithMarkModelModificationAndTypeIdParts: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    withMarkModelModificationAndTypeId: action.value.withMarkModelModificationAndTypeId,
                },
            }
        }
        case storeDispatchTypes.setWithCarId: {
            return { ...state, filters: { ...state.filters, withCarId: action.value.withCarId } }
        }
        case storeDispatchTypes.setWithAvitoFullInfo: {
            return { ...state, filters: { ...state.filters, withAvitoFullInfo: action.value.withAvitoFullInfo } }
        }
        case storeDispatchTypes.setPartListParamsMarketplace: {
            return { ...state, filters: { ...state.filters, marketplace: action.value.marketplace } }
        }
        case storeDispatchTypes.setPartsLoader:
            return { ...state, loaders: { ...state.loaders, [action.value.field]: action.value.value } }
        case storeDispatchTypes.setApplicabilitySelected:
            return { ...state, applicability: { ...state.applicability, applicabilitySelected: action.value } }
        case storeDispatchTypes.setApplicabilitySuggestion:
            return { ...state, applicability: { ...state.applicability, applicabilitySuggestion: action.value } }
        case storeDispatchTypes.setPartType:
            return { ...state, partType: action.value }
        case storeDispatchTypes.setSelectAllParts:
            return { ...state, selectedParts: { ...state.selectedParts, selectAll: action.value, parts: [] } }
        case storeDispatchTypes.setSelectedParts:
            return { ...state, selectedParts: { ...state.selectedParts, parts: action.value } }
        case storeDispatchTypes.setCurrentPart:
            return { ...state, currentPart: action.value }
        case storeDispatchTypes.setCustomizableColumns:
            return { ...state, customizableColumns: action.value }
        case storeDispatchTypes.setCurrentCurrency:
            return { ...state, currentCurrency: action.value }
        case storeDispatchTypes.setIsPartSavedSuccessfully:
            return { ...state, isPartSavedSuccessfully: { partId: action.value.partId, success: action.value.success } }
        case storeDispatchTypes.removeIsPartSavedSuccessfully: {
            const { isPartSavedSuccessfully, ...rest } = state
            return { ...rest }
        }
        case storeDispatchTypes.updatePartsWithIncomingInvoicesTitle: {
            const documents = action.value
            const updatesPartListData = state.partList.data.map(part => {
                const document = documents.find((document: IncomingInvoice) => document.partsId.includes(part.id))
                return { ...part, incomingInvoiceTitle: document?.title ?? part.incomingInvoiceTitle }
            })
            return { ...state, partList: { ...state.partList, data: updatesPartListData } }
        }
        default: {
            return state
        }
    }
}

export default createPartListReducer
