import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import Admin from '../pages/Admin'
import Auth from '../pages/Auth'

import { adminRoutes } from './adminRoutes'
import NavigateWithParams from './NavigateWithParams'

const AppRoutes = () =>
    useRoutes([
        {
            path: '/admin',
            element: <Admin />,
            children: adminRoutes,
        },
        {
            path: '/auth',
            element: <Auth />,
        },
        {
            path: '/parts/:partId',
            element: <NavigateWithParams to={`/admin/parts/:partId`} />,
        },
        {
            path: '*',
            element: <Navigate to={'/admin/parts'} />,
        },
    ])

export default AppRoutes
