import React, { useEffect, useState } from 'react'
import { XCircleFill } from 'react-bootstrap-icons'
import { useDispatch } from 'react-redux'

import { useSelector } from '../../../modules/store/customSelector'
import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'
import { SortFieldParts, SortIconType, SortOrder } from '../../../types/common/commonTypes'
import { convertToWords } from '../../../utils/converters'
import ColumnSort from '../../_molecules/ColumnSort/ColumnSort'
import RangeInput from '../../RangeInput/RangeInput'

import styles from './styles.module.scss'

const getTableHeadFontSize = (tableHead: string, initialTableHead: string) => {
    if (tableHead === initialTableHead) {
        return 16
    }
    const tableHeadLength = tableHead.length
    if (tableHeadLength >= 8 && tableHeadLength <= 13) {
        return 14
    } else if (tableHeadLength > 13) {
        return 12
    } else {
        return 16
    }
}

interface IProps {
    slice?: 'partList' | 'actionLogList'
    initialTableHead: string
    initialFromVal: number | undefined
    initialToVal: number | undefined
    widthInPixels: number
    leftInPixes?: number
    rightInPixels?: number
    step: number
    storeActionSetRange: string
    numOnly?: boolean
    isColumnSort?: boolean
    justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
}

const TableHeadRangeFilter = ({
    slice = 'partList',
    initialTableHead,
    initialFromVal,
    initialToVal,
    widthInPixels,
    leftInPixes,
    rightInPixels,
    step,
    storeActionSetRange,
    numOnly,
    isColumnSort = false,
    justifyContent,
}: IProps) => {
    const dispatch = useDispatch()
    const [isEditable, setIsEditable] = useState(false)
    const [tableHead, setTableHead] = useState(initialTableHead)
    const [fromVal, setFromVal] = useState(initialFromVal ? String(initialFromVal) : '')
    const [toVal, setToVal] = useState(initialToVal ? String(initialToVal) : '')
    const [isClearVisible, setIsClearVisible] = useState(false)
    const [tableHeadFontSize, setTableHeadFontSize] = useState(getTableHeadFontSize(tableHead, initialTableHead))
    const [isSortActive, setIsSortActive] = useState(false)

    const sortOrderCurrent = useSelector(state =>
        state.partList.sortOrder.find(item => item[0] === SortFieldParts.PRICE),
    )

    let sortActionType: storeDispatchTypes
    let rangeActionType: storeDispatchTypes
    switch (slice) {
        case 'partList':
            sortActionType = storeDispatchTypes.setPartListSort
            rangeActionType = storeDispatchTypes.setPriceRangeParts
            break
        case 'actionLogList':
            sortActionType = storeDispatchTypes.setActionLogListSort
            rangeActionType = storeDispatchTypes.setPriceRangeActionLogList
            break
    }

    const clearFilter = () => {
        if (isClearVisible && isSortActive) {
            dispatch({
                type: sortActionType,
                value: {
                    sortField: SortFieldParts.PRICE,
                    sortOrder: SortOrder.NONE,
                },
            })
            dispatch({
                type: rangeActionType,
                value: {
                    from: null,
                    to: null,
                },
            })
        } else if (isClearVisible) {
            setTableHead(initialTableHead)
            setFromVal('')
            setToVal('')
        } else if (isSortActive) {
            dispatch({
                type: sortActionType,
                value: {
                    sortField: SortFieldParts.PRICE,
                    sortOrder: SortOrder.NONE,
                },
            })
        }

        setIsClearVisible(false)
        setIsSortActive(false)
    }

    const handleSortChange = (isActive: boolean) => {
        setIsSortActive(isActive)
    }

    useEffect(() => {
        setTableHeadFontSize(getTableHeadFontSize(tableHead, initialTableHead))
    }, [tableHead])

    useEffect(() => {
        if (fromVal !== '' && toVal === '') {
            setTableHead(`от ${convertToWords(fromVal, numOnly)}`)
        } else if (fromVal === '' && toVal !== '') {
            setTableHead(`до ${convertToWords(toVal, numOnly)}`)
        } else if (fromVal !== '' && toVal !== '') {
            setTableHead(`${convertToWords(fromVal, numOnly)} - ${convertToWords(toVal, numOnly)}`)
        } else {
            setTableHead(initialTableHead)
        }
    }, [toVal, fromVal])

    useEffect(() => {
        if (!isEditable && (fromVal !== '' || toVal !== '')) {
            setIsClearVisible(true)
        } else {
            setIsClearVisible(false)
        }
    }, [isEditable])

    useEffect(() => {
        if (
            !isEditable &&
            (fromVal !== (initialFromVal ? String(initialFromVal) : '') ||
                toVal !== (initialToVal ? String(initialToVal) : ''))
        ) {
            dispatch({
                type: storeActionSetRange,
                value: {
                    from: fromVal,
                    to: toVal,
                },
            })
        }
    }, [isEditable, fromVal, toVal])

    useEffect(() => {
        if (!initialFromVal && fromVal) {
            setFromVal('')
            setIsClearVisible(false)
        }
        if (!initialToVal && toVal) {
            setToVal('')
            setIsClearVisible(false)
        }
        if (initialFromVal) {
            setFromVal(initialFromVal ? String(initialFromVal) : '')
            setIsClearVisible(true)
        }
        if (initialToVal) {
            setToVal(initialToVal ? String(initialToVal) : '')
            setIsClearVisible(true)
        }
    }, [initialFromVal, initialToVal])

    useEffect(() => {
        if (sortOrderCurrent && isColumnSort) {
            setIsSortActive(true)
        } else {
            setIsSortActive(false)
        }
    }, [sortOrderCurrent])

    return (
        <div className={styles.tableHead}>
            <div className={styles.wrap} style={{ justifyContent }}>
                <>
                    <span
                        className={styles.tableHead}
                        style={{ fontSize: tableHeadFontSize }}
                        onClick={() => setIsEditable(true)}
                    >
                        {tableHead}
                    </span>
                </>
                {isColumnSort && (
                    <ColumnSort
                        iconType={SortIconType.NUMERIC}
                        sortField={SortFieldParts.PRICE}
                        slice={slice}
                        reverseSort={false}
                        isResetButton={false}
                        handleSortChange={handleSortChange}
                    />
                )}
                {(isClearVisible || isSortActive) && (
                    <span className={styles.clearIcon} onClick={clearFilter}>
                        <XCircleFill color="grey" size={14} />
                    </span>
                )}
            </div>

            {isEditable && (
                <RangeInput
                    widthInPixels={widthInPixels}
                    leftInPixels={leftInPixes}
                    rightInPixels={rightInPixels}
                    step={step}
                    setIsEditable={setIsEditable}
                    tableHead={tableHead}
                    setTableHead={setTableHead}
                    fromVal={fromVal}
                    setFromVal={setFromVal}
                    toVal={toVal}
                    setToVal={setToVal}
                />
            )}
        </div>
    )
}

export default TableHeadRangeFilter
